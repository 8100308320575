import { Link, useStaticQuery } from "gatsby";
import Icon from "./icon";
import { cn } from "../lib/helpers";
import * as styles from "./header.module.css";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { graphql } from "gatsby";
import {
  AppBar,
  Typography,
  Toolbar,
  ListItem,
  List,
  IconButton,
  Hidden,
  Drawer,
  Grid,
  ClickAwayListener,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import logo from "./icon/LogoLarge.svg";
import logoSmall from "./icon/LogoSmall.svg";
import CampusLogo from "./icon/Campus.svg";
import AvatarDefaultSmall from "./icon/avatar-default-small.svg";
import AvatarDefaultSmallB2C from "./icon/avatar-b2c-small.svg"
// import VisibleLogo from "./icon/visible.png";
import VisibleLogo from "./icon/VisibleLogo.svg";
// import VisibleLogoMobile from "./icon/visible-mobile.png";
import VisibleLogoMobile from "./icon/VisibleMobile.svg";
import HeaderMenu from "./header-menu";
import HeaderMenuMobile from "./header-menu-mobile";
import { styled } from '@mui/system';
import HeaderMenuTest from "./headerTest"
import {
  compose,
  spacing,
  palette,
  styleFunctionSx
} from "@material-ui/system";
import MuiGrid from "@material-ui/core/Grid";
import useCampusHeader from "../hooks/useCampusHeader"
import ContainerForLinks from "./containerForLinks";
import anchorMenu from "./sidebarComponents/anchorMenu";
import LoginAvatar from "./icon/login-avatar-b2c.svg";
import SignupIcon from "./icon/signup-avatar-b2c.svg";
import MembersArea from "./icon/members-area-avatar.svg";
import MemberLogout from "./icon/members-logout-icon.svg";

import LoginButton from "./auth0/LoginButton"
import AuthenticatorNoRedirect from "./auth0/AuthenticatorNoRedirect";

const styleFunction = styleFunctionSx(compose(spacing, palette));
const StyledGrid = styled(Grid)(styleFunction);

const drawerWidth = "100%";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  bodyContainer: {
    maxWidth: "1170px",
    "@media (min-width:900px) and (max-width:959px)": {
      // flex: "1",
    },
  },
  bodyContainerB2C: {
    maxWidth: "1170px",
    "@media (min-width:900px) and (max-width:959px)": {
      // flex: "1",
    },
    paddingTop: "24px",
    paddingBottom: "24px",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    width: "100%",
    boxShadow: "0px 2px 4px 0px #4040401A",
    marginBottom: "50px",
    height: "72px",
    "@media (max-width:959px)": {
      height: "44px",
    },
  },
  drawerHeaderCourse: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    width: "100%",
    boxShadow: "0px 2px 4px 0px #4040401A",
    height: "72px",
    backgroundColor: "#6464FF",
    "@media (max-width:959px)": {
      height: "44px",
    },
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    height: "100%",
    width: "80%",
    flexGrow: 0,
    // sx={{ display: { xl: 'flex !important', lg: 'flex !important', md: 'flex !important', sm: 'none !important', xs: 'none !important' } }}
    [theme.breakpoints.down("xl")]:{
      display: "flex !important",
    },
    [theme.breakpoints.down("sm")]:{
      display: "none !important",
    },
  },
  headerCourse: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    height: "100%",
    width: "80%",
    flexGrow: 0,
    // sx={{ display: { xl: 'flex !important', lg: 'flex !important', md: 'flex !important', sm: 'none !important', xs: 'none !important' } }}

    [theme.breakpoints.down("xl")]:{
      display: "flex !important",
    },
    [theme.breakpoints.down(1220)]:{
      display: "none !important",
    },
    [theme.breakpoints.down("sm")]:{
      display: "none !important",
    },
  },
  anchorHolder:{

    [theme.breakpoints.down(1220)]:{
      display: "none !important",
    },
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#ffffff",
  },
  appBar: {
    boxShadow: "0px 2px 4px rgba(64, 64, 64, 0.1)",
    height: "72px",
    justifyContent: "center",
    "@media (max-width:959px)": {
      height: "44px",
    },
    "@media (min-width:960px) and (max-width:959px)": {
      height: "72px",
    },
  },
  appBarBlur: {
    boxShadow: "0px 2px 4px rgba(64, 64, 64, 0.1)",
    height: "72px",
    justifyContent: "center",
    filter: "blur(5px)",
    "@media (max-width:959px)": {
      height: "44px",
    },
  },
  headerMenu: {
    display: "flex",
    flexDirection: "row",
  },
  toolbar: {
    padding: 0,
    alignItems: "center",
    "& div:nth-of-type(4)": {
      marginRight: "auto",

    },
    "& div:nth-of-type(5)": {
      marginRight: "4.166666666666667%",
      marginLeft: "auto",
      [theme.breakpoints.between("900", "959")]:{
        // marginRight: "0",
      },
    },
  },

  toolbarVisible: {
    padding: 0,
    alignItems: "center",
    "& div:nth-of-type(4)": {
      marginRight: "auto",
      marginLeft: "4.166666666666667%",
      [theme.breakpoints.between("960", "1220")]:{
        marginLeft: "8.167%"
      }
    },
    "& div:nth-of-type(5)": {
      marginRight: "4.166666666666667%",
      marginLeft: "auto",
      [theme.breakpoints.between("960", "1220")]:{
        marginRight: "8.167%"
      }
    },
  },
  headerListText: {
    color: "#434A54",
    "&:hover": {
      color: "#FFFFFF",
    },
  },
  mainHeaderListItem: {
    color: "#434A54",
    height: "72px",
    fontWeight: 500,
    flexGrow: 0,
    width: "auto",
    padding: "0",
    margin: "0",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    textAlign: "center",
    justifyContent: "center",
    "&:hover": {
      backgroundColor: "#FF0F5F",
      color: "#FFFFFF",
    },
  },
  mainHeaderListItemCourse: {
    color: "#434A54",
    height: "72px",
    fontWeight: 500,
    flexGrow: 0,
    width: "auto",
    padding: "0",
    margin: "0",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    textAlign: "center",
    justifyContent: "center",
    "&:hover": {
      backgroundColor: "#5B5AC7 !important",
      color: "#FFFFFF",
    },
  },
  mainHeaderListItemCourseAccount: {
    color: "#434A54",
    height: "72px",
    fontWeight: 500,
    flexGrow: 0,
    width: "auto",
    padding: "0",
    margin: "0",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    textAlign: "center",
    justifyContent: "center",
    "&:hover": {
      color: "#FFFFFF",
    },
    [theme.breakpoints.between("900", "959")]:{
      display: "none",
    },
  },
  brandingSmall: {
    position: "absolute",
    left: "4.166666666666667%",
    "@media (min-width:600px)": {},

  },
  brandingSmallDrawerCourse: {

    position: "absolute",
    left: "4.166666666666667%",
    "& img": {
      height: "80px",
    },
    "@media (min-width:600px)": {},
  },
  brandingSmallCourse: {
    display: "flex",
    "& img": {
      height: "80px",
    },
    [theme.breakpoints.down(1220)]:{
      display: "inherit",
    },
    [theme.breakpoints.up(1220)]:{
     display: "none",
    },
    "@media (min-width:600px)": {},
    "& a": {
      display: "inline-flex",
    },
  },
  red: {
    backgroundColor: "red",
  },
  menuHelper: {
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-end",
      position: "relative",
    },
    // [theme.breakpoints.between("900", "959")]:{
    //   flexDirection: "row-reverse",
    // },
  },
  menuButton: {
    paddingLeft: "0",
    paddingRight: "0",
    // marginLeft: "auto",
    // paddingRight: "4.166666666666667%",
    // "@media (min-width:900px) and (max-width:959px)": {
    //   visibility: "hidden",
    // },
  },
  cookieHelper: {
    // flex: "1"
  },
  menuButtonClose: {
    padding: "0 4.166666666666667% 0 0",
  },
  campusLogo: {
    height: "32px !important",
  },
  drawerMenuTitle: {
    fontStyle: "normal",
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "30px",
    color: "#FFFFFF",
    textDecoration: "none",
  },
  courseAnchor: {
    display: "flex",
  },
  menuText: {
    textDecoration: "none",
  },
  accountMenuIcon: {
    right: '20px',
    cursor: 'pointer',
    padding: 0,
    position: 'relative',
    verticalAlign: 'middle'
  },
  accountMenuIconB2C: {
    cursor: 'pointer',
    padding: 0,
    position: 'relative',
    verticalAlign: 'middle',
    right: "36px",
  },
  accountMenuContainer: {
    position: 'absolute',
    left: 0,
    backgroundColor: '#FFFFFF',
    width: '100%',
    top: '44px',
    justifyContent: 'space-between',
    [theme.breakpoints.between("960", "1220")]:{
      top: "72px",
    },
    [theme.breakpoints.up(1220)]:{
      display: "hidden",
    },
  },
  accountMenuContainerB2C: {
    position: 'absolute',
    left: 0,
    backgroundColor: '#FFFFFF',
    width: '100%',
    top: '44px',
    justifyContent: 'space-between',
    [theme.breakpoints.between("960", "1220")]:{
      top: "72px",
    },
    [theme.breakpoints.up(1220)]:{
      display: "hidden",
    },
    boxShadow: "0px 0px 1px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05)",
  },
  usernameLabel: {
    display: 'block',
    fontFamily: 'Poppins',
    fontSize: '18px',
    lineHeight: '27px',
    fontWeight: 700,
    color: "#6464FF",
    paddingTop: '1em',
    paddingBottom: '1em',
    borderBottom: '0.5px solid #DCDCE4'
  },
  accountMenuLabel: {
    display: 'block',
    fontFamily: 'Poppins',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#3C465F',
    paddingTop: '10px',
    paddingBottom: '10px'
  },
  accountMenuLabelB2C: {
    display: 'block',
    fontFamily: 'Poppins',
    fontSize: '16px',
    lineHeight: "20px",
    letterSpacing: "-0.24px",
    color: "#2F2F4E",
  },
  accountMenuAnchor: {
    display: "flex",
    textDecoration: 'none'
  },
  accountMenuAnchorBottom: {
    display: "flex",
    paddingTop: "24px",
    textDecoration: 'none',
  },
  drawerContainer: {
    [theme.breakpoints.up(1220)]: {
      display: "none",
    },
  },
  logoAnchor:{
    display: "flex",
    height: "100%",
  },
  logoAnchorSmall:{
    display: "flex",
  },
  icon:{
    paddingRight: "12px",
  },
}));

function Header(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [accountMenuOpen, setAccountMenuOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  var isAuthed = false;


  if(typeof window !== "undefined") {
    isAuthed = AuthenticatorNoRedirect()
  }

  useEffect(() => {
      if(typeof window !== "undefined") {
        isAuthed = AuthenticatorNoRedirect()
      }
  },[isAuthed])

  const { isBlur, isCoursePage } = props;

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };


  const data = useStaticQuery(graphql`
    query HeadingQueryTEST {
     sanityMenu(title: {eq: "Header Menu"}) {
      title
      menuLinksTop {
        text
        reference {
          ... on SanityInformation {
            id
            slug {
              current
            }
            subfolder{
              folder
            }
            _type
          }
          ... on SanityLandingPage {
            slug {
              current
            }
            _type
            subfolder {
              folder
            }
          }
          ... on SanityLinkCategory {
            url
            _type
          }
          ... on SanityPost {
            _type
            slug {
              current
            }
          }
          ... on SanityQuestionPost {
            _type
            slug {
              current
            }
          }
          ... on SanityWidgetCategory {
              id
              title
              _type
            }
        }
        menuChildLinks {
          text
          reference {
            ... on SanityInformation {
              id
              slug {
                current
              }
            }
            ... on SanityLandingPage {
              _type
              slug {
                current
              }
              subfolder {
                folder
              }
            }
            ... on SanityLinkCategory {
              url
              _type
            }
            ... on SanityPost {
              _type
              slug {
                current
              }
            }
            ... on SanityQuestionPost {
              slug {
                current
              }
              _type
            }
            ... on SanityWidgetCategory {
              id
              title
              _type
            }
          }
          menuChildLinks {
            text
            reference {
              ... on SanityWidgetCategory {
                id
                title
                _type
              }
              ... on SanityInformation {
                subfolder {
                  folder
                }
                slug {
                  current
                }
                _type
              }
              ... on SanityLinkCategory {
                url
                _type
              }
              ... on SanityPost {
                slug {
                  current
                }
                _type
              }
              ... on SanityQuestionPost {
                id
                _type
                slug {
                  current
                }
              }
              ... on SanityLandingPage {
                _type
                slug {
                  current
                }
                subfolder {
                  folder
                }
              }

            }
          }
        }
      }
    }
    }
  `);

  const campusData = useCampusHeader();

  const [accountMenuItem, setAccountMenuItem] = useState(null);

  useEffect(() => {
    var accountMenuItem = campusData.sanityMenu.menuLinksTop.find(obj => {
      return obj.text.toLowerCase() === 'account'
    });

    setAccountMenuItem(accountMenuItem);
  }, []);

  //console.log('accountMenuItem: ', accountMenuItem);

  const handleAccountMenuClickAway = function () {
    setAccountMenuOpen(false);
  }


  function drawer(data) {
    return (
      <header>
        <Grid container className={isCoursePage ? classes.drawerHeaderCourse : classes.drawerHeader}>
          <div className={isCoursePage ? classes.brandingSmallDrawerCourse : classes.brandingSmall}>
            <Link className={isCoursePage ? classes.menuText : classes.logoAnchor} to={isCoursePage ? "/visible/members/" : "/"}>
              {isCoursePage ? <Typography className={classes.drawerMenuTitle} variant="h5">Menu</Typography> :
                <img src={logoSmall} alt="Site Logo" />
              }
            </Link>
          </div>
          <IconButton
            color="inherit"
            onClick={handleDrawerToggle}
            className={classes.menuButtonClose}
            aria-label="close drawer"
          >
            {isCoursePage ? <Icon symbol="courseCloseMenu" /> :
              <Icon symbol="closeMenu" />
            }
          </IconButton>
        </Grid>
        <nav className={cn(styles.nav && styles.showNav)}>
          <Grid className={classes.navbarLinks}>
            {isCoursePage ? campusData.sanityMenu.menuLinksTop.map(
              ({ text, reference, menuChildLinks, menuIcon }) => (
                <Grid key={text}>

                  <HeaderMenuMobile
                    text={text}
                    links={menuChildLinks}
                    reference={reference}
                    isCoursePage={isCoursePage}
                    menuIcon={menuIcon}
                  ></HeaderMenuMobile>

                  {/* for now to / */}
                </Grid>
              )
            ) : data.sanityMenu.menuLinksTop.map(
              ({ text, reference, menuChildLinks, menuIcon }) => (
                <Grid key={text}>

                  <HeaderMenuMobile
                    text={text}
                    links={menuChildLinks}
                    reference={reference}
                    isCoursePage={isCoursePage}
                    menuIcon={menuIcon}
                  ></HeaderMenuMobile>

                  {/* for now to / */}
                </Grid>
              )
            )}
          </Grid>
        </nav>
      </header>
    );
  }
  return (
    <>
      <div className={styles.root}>
        <div className={styles.wrapper}>
          <AppBar position="sticky" className={isBlur ? classes.appBarBlur : classes.appBar}>
            <Toolbar className={isCoursePage ? classes.toolbarVisible : classes.toolbar}>
              <Grid container item className={classes.menuHelper}>
                <StyledGrid item xs={1} sx={{ display: { xl: 'flex !important', lg: 'flex !important', md: 'flex !important', sm: 'none !important', xs: 'none !important' } }}></StyledGrid>
                <Grid
                  xs={10}
                  container
                  item
                  alignItems="center"
                  justify="space-between"
                  className={classes.bodyContainer}
                >
                  <Hidden smDown implementation="css">
                    <div className={isCoursePage ? classes.anchorHolder : classes.branding}>
                      <Link className={isCoursePage ? classes.courseAnchor : classes.logoAnchor} to={isCoursePage ? "/visible/members/" : "/"}>
                        <img src={isCoursePage ? VisibleLogo : logo} alt="Site Logo" />
                      </Link>
                    </div>
                  </Hidden>

                  <Grid className={classes.red}></Grid>
                  {/* work here */}

                  <StyledGrid className={isCoursePage ? classes.headerCourse : classes.header} >
                    {isCoursePage ? campusData.sanityMenu.menuLinksTop.map(
                      ({ text, menuChildLinks, reference }, index) => (
                        <ListItem
                          key={text}
                          className={isCoursePage ? `${text.toLowerCase() === "account" ? classes.mainHeaderListItemCourseAccount : classes.mainHeaderListItemCourse}` : classes.mainHeaderListItem}
                        >
                          {/* <HeaderMenu
                              className={classes.mainHeaderListItem}
                              text={text}
                              links={menuChildLinks}
                              reference={reference}
                              data={data}
                            ></HeaderMenu> */}
                          <HeaderMenuTest
                            isAuthed={isAuthed}
                            text={text}
                            links={menuChildLinks}
                            reference={reference}
                            data={data}
                            index={index}
                            length={isCoursePage ? campusData.sanityMenu.menuLinksTop.length : data.sanityMenu.menuLinksTop.length}
                            isCoursePage={isCoursePage}
                          />
                        </ListItem>
                      )
                    ) : data.sanityMenu.menuLinksTop.map(
                      ({ text, menuChildLinks, reference }, index) => (
                        <ListItem
                          key={text}
                          className={isCoursePage ? `${text.toLowerCase() === "account" ?
                              classes.mainHeaderListItemCourseAccount : classes.mainHeaderListItemCourse}` : classes.mainHeaderListItem}
                        >
                          {/* <HeaderMenu
                              className={classes.mainHeaderListItem}
                              text={text}
                              links={menuChildLinks}
                              reference={reference}
                              data={data}
                            ></HeaderMenu> */}
                          <HeaderMenuTest
                            isAuthed={isAuthed}
                            text={text}
                            links={menuChildLinks}
                            reference={reference}
                            data={data}
                            index={index}
                            length={isCoursePage ? campusData.sanityMenu.menuLinksTop.length : data.sanityMenu.menuLinksTop.length}
                            length={data.sanityMenu.menuLinksTop.length}
                            isCoursePage={isCoursePage}
                          />
                        </ListItem>
                      )
                    )}
                  </StyledGrid>
                  {/*<LoginButton />*/}
                </Grid>
                <Grid item xs={1}></Grid>
                <Hidden mdUp={!isCoursePage && true}>
                  <div className={isCoursePage ? classes.brandingSmallCourse : classes.brandingSmall}>
                    <Link to={isCoursePage ? "/visible/members/" : "/"} className={isCoursePage ? null : classes.logoAnchorSmall}>
                      <img className={isCoursePage ? classes.campusLogo : null} src={isCoursePage ? VisibleLogoMobile : logoSmall} alt="Site Logo" />
                    </Link>
                  </div>
                </Hidden>
                 <Hidden mdUp implementation={"css"}>
                  <img className={classes.accountMenuIconB2C} src={AvatarDefaultSmallB2C} onClick={() => { setAccountMenuOpen(!accountMenuOpen) }} />
                  <IconButton
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                    className={classes.menuButton}
                  >
                    <Icon symbol={"hamburger"} />
                  </IconButton>
                  </Hidden>

              </Grid>
            </Toolbar>
          </AppBar>
            {accountMenuOpen && <ClickAwayListener onClickAway={handleAccountMenuClickAway}>
              <Grid container className={classes.accountMenuContainerB2C}>
                <Grid item md={1}></Grid>
                <Grid
                  md={10}
                  xs={11}
                  container
                  item
                  alignItems="center"
                  justify="space-between"
                  className={classes.bodyContainerB2C}
                >
                  {isAuthed &&
                    <>
                      <Grid item xs={12}><a className={classes.accountMenuAnchor} href={"/members"}>
                        <img src={MembersArea} className={classes.icon}/>
                        <Typography variant="body1" className={classes.accountMenuLabelB2C}>Members area</Typography>
                      </a></Grid>
                      <Grid item xs={12}><a className={isCoursePage ? classes.accountMenuAnchor : classes.accountMenuAnchorBottom} href="/logout">
                        <img src={MemberLogout} className={classes.icon}/>
                        <Typography variant="body1" className={isCoursePage ? classes.accountMenuLabel : classes.accountMenuLabelB2C}>Logout</Typography>
                      </a></Grid>
                    </>
                  }
                  {!isAuthed &&
                    <>
                    <Grid item xs={12}><a className={classes.accountMenuAnchor} href={"/login"}>
                      <img src={LoginAvatar} className={classes.icon}/>
                      <Typography variant="body1" className={classes.accountMenuLabelB2C}>Login</Typography>
                    </a></Grid>
                    <Grid item xs={12}><a className={classes.accountMenuAnchorBottom} href="/sign-up">
                      <img src={SignupIcon} className={classes.icon}/>
                      <Typography variant="body1" className={classes.accountMenuLabelB2C}>Join Boring Money</Typography>
                    </a></Grid>
                    </>
                  }
                </Grid>
                <Grid item md={1}></Grid>
              </Grid>
            </ClickAwayListener>}
          <Hidden smUp implementation="css">
            <nav className={classes.drawer} aria-label="to be added">
              {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
              <Drawer
                disableEnforceFocus
                variant="temporary"
                anchor={theme.direction === "rtl" ? "left" : "right"}
                open={mobileOpen}
                onClose={handleDrawerToggle}
                classes={{
                  paper: classes.drawerPaper,
                }}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
              >
                {drawer(data)}
              </Drawer>
            </nav>
          </Hidden>
        </div>
      </div>
    </>
  );
}

export default connect(state => ({
  isBlur: state.blurReducer.isBlur
}), null)(Header)
