// @ts-ignore
import React from "react";
import BasePortableText from "@sanity/block-content-to-react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Hidden, Typography } from "@material-ui/core";
// @ts-ignore
import clientConfig from "../../../client-config";
// @ts-ignore
import serializers from "../serializers";

type LessonProps = {
  title?: string;
  isActiveLesson: boolean;
  rawIntroPara: object[];
  courseColour: string;
  theme: string;
};

const Lesson = ({
  title,
  isActiveLesson,
  rawIntroPara,
  courseColour,
  theme,
  isCoursePage,
}: LessonProps): JSX.Element => {

  const useStyles = makeStyles((theme) => ({
    lessonContainer: {
      width: "100%",
      flexDirection: "column",
      "& *": {
        color: "#3C465F !important",
      },
      "& > div":{
        display: "contents",
      },
      "& h2, h3, h4, h1, h5, h6": {
        color: "#6464F6 !important",
      },
      "& a":{
        color: "#6464FF !important",
        textDecoration: "none",
      },
      "& a:hover":{
        textDecoration: "underline",
      },
      "& .iframeHolder":{
        "& > div":{
          borderRadius: 0,
          border: "2px solid #d4d4d4",
        }
      }
    },
    lessonContainerMainSite: {
      width: "100%",
      flexDirection: "column",
      "& *": {
        color: "#2F2F4E; !important",
      },
      "& > div":{
        display: "contents",
      },
      "& h2, h3, h4, h1, h5, h6": {
        color: "#262641 !important",
      },
      "& a":{
        textDecoration: "none",
        color: "#0070EA",
      },
      "& a:hover":{
        textDecoration: "underline",
      },
      "& .iframeHolder":{
        "& > div":{
          boxShadow: "0px 2px 4px rgba(64, 64, 64, 0.1)",
          borderRadius: "12px",
          border: "1px solid #DBE3EF",
        }
      }
    },
  }))

  // const greenSpecificStyles = makeStyles((theme) => ({
  //   lessonContainer: {
  //     width: "100%",
  //     flexDirection: "column",
  //     "& *": {
  //       color: "#3C465F !important",
  //     },
  //     "& > div":{
  //       display: "contents",
  //     },
  //     "& h2, h3, h4, h1, h5, h6": {
  //       color: "#6464F6!important",
  //     },
  //     "& .iframeHolder":{
  //       "& > div":{
  //         borderRadius: 0,
  //         border: "2px solid #d4d4d4",
  //       }
  //     }
  //   },
  // }))
  //
  // const orangeSpecificStyles = makeStyles((theme) => ({
  //   lessonContainer: {
  //     width: "100%",
  //     flexDirection: "column",
  //     "& *": {
  //       color: "#3C465F !important",
  //     },
  //     "& > div":{
  //       display: "contents",
  //     },
  //     "& h2, h3, h4, h1, h5, h6": {
  //       color: "#6464F6 !important",
  //     },
  //     "& .iframeHolder":{
  //       "& > div":{
  //         borderRadius: 0,
  //         border: "2px solid #d4d4d4",
  //       }
  //     }
  //   },
  // }))

  // const redStyles = redSpecificStyles();
  // const greenStyles = greenSpecificStyles();
  // const orangeStyles = orangeSpecificStyles();
  //
  // const getCourseColour = (courseColour) => {
  //
  //   if (courseColour === "red"){
  //     return redStyles;
  //   } else if (courseColour === "green"){
  //     return greenStyles;
  //   } else if (courseColour === "orange"){
  //     return orangeStyles;
  //   }
  // }
  //
  // const colourSpecificClasses =  getCourseColour(courseColour)

  const classes = useStyles();
  if (isActiveLesson) {
    return (
      <Grid container className={isCoursePage ? classes.lessonContainer : classes.lessonContainerMainSite}>
        <BasePortableText
          blocks={rawIntroPara}
          serializers={serializers}
          {...clientConfig.sanity}
        />
      </Grid>
    );
  } else {
    return null;
  }
};

export default Lesson;
