import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as styles from "./headerTest.module.css";
import HeaderMenu from "./header-menu";
import { Link } from "gatsby";
import { Typography, Button, Menu, MenuItem, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ContainerForLinks from "./containerForLinks";
import { toggleMenuItemHover } from "../redux/reducers/menuItemHover";
import AvatarDefaultB2C from "./icon/avatar-b2c.svg";
import AvatarChevronDown from "./icon/avatar-chevron-down.svg";
import AvatarChevronDownB2C from "./icon/avatar-chevron-down-b2c.svg";
import CampusCTA from "./landingPageComponents/blocks/reuseableCampusComponents/campusCTA";
import { useAuth0 } from "@auth0/auth0-react";
import AuthenticatorNoRedirect from "./auth0/AuthenticatorNoRedirect";

const useStyles = makeStyles((theme) => ({
  headerListItem: {
    color: "#4E4B66",
    wordWrap: "break-word",
    height: "72px",
    fontSize: "14px",
    fontWeight: 500,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    textAlign: "center",
    justifyContent: "center",
    paddingLeft: "14px",
    paddingRight: "14px",
    wordBreak: "keep-all",
    "&:hover": {
      backgroundColor: "#FF0F5F",
      color: "#FFFFFF",
    },
    // [theme.breakpoints.up('lg')]: {
    //   fontSize: "16px",
    // },
  },
  headerListItemCourse: {
    color: "#3C465F",
    fontFamily: "Poppins",
    wordWrap: "break-word",
    height: "72px",
    fontSize: "16px",
    lineHeight: "20px",
    textTransform: "uppercase",
    fontWeight: 500,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    textAlign: "center",
    justifyContent: "center",
    paddingLeft: "14px",
    paddingRight: "14px",

    "&:hover": {
      backgroundColor: "#6464FF",
      color: "#FFFFFF",
    },
    // [theme.breakpoints.up('lg')]: {
    //   fontSize: "16px",
    // },
  },
  headerListLink: {
    color: "inherit",
    height: "72px",
    fontWeight: 500,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    textAlign: "center",

    justifyContent: "center",
    "&:hover": {
      backgroundColor: "#FF0F5F",
      color: "#FFFFFF",

    },
    textDecoration: "none",
  },
  headerListLinkCourse :{
    color: "inherit",
    height: "72px",
    fontWeight: 500,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    textAlign: "center",

    justifyContent: "center",
    "&:hover": {
      backgroundColor: "#5B5AC7",
      color: "#FFFFFF",
    },
    textDecoration: "none",
  },
  nav: {
    display: "flex",
    transformStyle: "preserve-3d",
    position: "relative",
    zIndex: 2,
    margin: "0px auto",
    perspective: "2000px",
    flexWrap: "wrap",
  },
  hoveringHelper: {
    color: "#ffffff",
  },
  hoveringHelperCourse: {
    color: "#ffffff",
    backgroundColor: "#6464FF",
  },
  accountMenuText: {
    display: "flex",
    padding: "1rem",
    alignItems: "center",
  },
  downChevron:{
    marginLeft: "12px",
    // height: "24px",
    // width: "24px",
  },
  accountButtonsHolder: {
    gap: "8px",
    paddingLeft: "1rem",
    display: "flex",
    alignItems: "center",
  },
}));

function Header(props) {
  const classes = useStyles();
  const { data, text, links, reference, index, length, isBlur, isMenuItemHover, dispatch, isCoursePage, isAuthed } = props;
  const [hovering, setHovering] = React.useState("false");
  const [finishedCheckingLoggedIn, setFinishedCheckingLoggedIn] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { isAuthenticated } = useState();

  //console.log("props.links", props.links)
  useEffect(() => {
    if (!isMenuItemHover) {
      setHovering(false);
    }
  }, [isMenuItemHover]);


  useEffect(() => {

    setFinishedCheckingLoggedIn(true)
    if (isAuthed) {
      setIsLoggedIn(true);
    }
  }, []);

  useEffect(() => {
    if (hovering) {
      dispatch(toggleMenuItemHover(true));
    }
  }, [hovering]);

  function onMouseEnter(e) {
    if (e.target
        && e.target.className && (e.target.className.toString().includes('ask-question-popup')
        || e.target.className.toString().includes('qform'))) {
      return false;
    }

    setHovering(true)
  }
  function onMouseLeave(e) {
    setHovering(false)
  }

  const getHeaderListItemClass = () => isCoursePage ? classes.headerListItemCourse : classes.headerListItem;

  const getHeaderListLinkClass = () => isCoursePage ? classes.headerListLinkCourse : classes.headerListLink;
  return (
    <nav id="menu" className={classes.nav}>
      <div className={`${styles.menuItem} ${props.text.toLowerCase() === "account" && isLoggedIn ?
        (styles.menuItemAccountB2C) : (props.text.toLowerCase() === "account" ? styles.menuItemB2C : null)}`}>

        { props.text.toLowerCase() === 'account' ?
          <div className={classes.accountMenuText}>
            <img src={AvatarDefaultB2C} />
            <img className={classes.downChevron} src={AvatarChevronDownB2C} />
          </div> :

          <div className={styles.menuText}>
            <ContainerForLinks
              reference={props.reference && props.reference}
              _type={props.reference && props.reference._type}
              customClassName={getHeaderListLinkClass()}
              isMenuItem={true}
            >
              <Typography
                variant="body1"
                color="inherit"
                className={`${getHeaderListItemClass()} ${
                  hovering === true && !isBlur ? (isCoursePage ? classes.hoveringHelperCourse : classes.hoveringHelper) : ""
                }`}
              >
                {props.text}
              </Typography>
            </ContainerForLinks>
          </div>
        }

          <div
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            className={`${styles.subMenu} ${index === length - 1 || index === length - 2 ? styles.subMenuLast : ""}`}
          >
            <div className={styles.iconBox}>
              <HeaderMenu
                text={text}
                links={links}
                reference={reference}
                data={data}
                isAccountMenu={props.text.toLowerCase() === 'account' ? true : false}
                isLoggedIn={isLoggedIn}
              ></HeaderMenu>
            </div>

            <div className={styles.subMenuHolder}></div>
          </div>
      </div>
      <div id="subMenuContainer">
        <div id="subMenuHolder">
          <div id="subMenu-bottom"></div>
        </div>
      </div>
    </nav>
  );
}

export default connect(state => ({
  isBlur: state.blurReducer.isBlur,
  isMenuItemHover: state.menuItemHoverReducer.isMenuItemHover
}), null)(Header)
