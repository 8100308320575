import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { Typography, Button, Menu, MenuItem, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ContainerForLinks from "./containerForLinks";
import LoginAvatar from "./icon/login-avatar-b2c.svg";
import SignupIcon from "./icon/signup-avatar-b2c.svg";
import MembersArea from "./icon/members-area-avatar.svg";
import MemberLogout from "./icon/members-logout-icon.svg";
import { useAuth0 } from "@auth0/auth0-react";

export default function HeaderMenu(props) {

  const {data, text, reference, links, isAccountMenu, isLoggedIn, isAccountMenuB2C} = props;
  const widthOfColumn = 230;
  let widthOfSubmenu;

  links.length > 0 ? (widthOfSubmenu = widthOfColumn * links.length + 82) : widthOfSubmenu = 0;


  const useStyles = makeStyles((theme) => ({
    headerListText: {
      color: "#434A54",
      "&:hover": {
        color: "#FFFFFF",
      },
    },
    // headerListItem: {
    //   color: "inherit",
    //   height: "72px",
    //   fontSize: "14px",
    //   fontWeight: 500,
    //   display: "flex",
    //   flexDirection: "row",
    //   alignItems: "center",
    //   textAlign: "center",
    //   justifyContent: "center",
    //   "&:hover": {
    //     backgroundColor: "#FF0F5F",
    //     color: "#FFFFFF",
    //   },
    //   // [theme.breakpoints.up('lg')]: {
    //   //   fontSize: "16px",
    //   // },
    // },
    // headerListLink: {
    //   color: "inherit",
    //   height: "72px",
    //   fontWeight: 500,
    //   display: "flex",
    //   flexDirection: "row",
    //   alignItems: "center",
    //   textAlign: "center",
    //   justifyContent: "center",
    //   "&:hover": {
    //     backgroundColor: "#FF0F5F",
    //     color: "#FFFFFF",
    //   },
    //   textDecoration: "none"
    // },
    subheaderLinkParent: {
      color: "#262641",
      textAlign: "Left",
      paddingBottom: "12px",
      height: '36px'
    },
    subheaderLinkChild: {
      color: "#262641",
      textAlign: "Left",
      paddingBottom: "12px",
    },
    subheaderLinkChildB2CLogin: {
      color: "#3C465F",
      textAlign: "Left",
    },
    subheaderColumn: {
      width: "100%",
      height: "100%",
    },
    subSubMenuItem: {
      padding: 0,
      whiteSpace: "normal"

    },
    fixedWidth: {
      width: widthOfColumn
    },
    subMenuItem: {
      whiteSpace: "normal"
    },
    dropdown: {
      float: "right",
      width: "100%",
      overflow: "hidden",

      flexGrow: 0,
      left: "50%",
      "&:hover $dropdownContent": {
        display: "flex",
        justifyContent: "center"
      }
    },
    dropdownContent: {
      backgroundColor: "#ffffff",
      boxShadow: "10px 10px 50px 3px #275C8D1A",
      zIndex: 1,
      overflow: "auto",
      width: widthOfSubmenu,
      borderRadius: "8px",
      "& a": {
        float: "none",
        textDecoration: "none",
        display: "block",
        textAlign: "left",
      },
    },
    dropdownContentB2CAvatar: {
      backgroundColor: "#ffffff",
      border: "1px solid #DBE3EF",
      padding: "40px",
      zIndex: 1,
      overflow: "auto",
      width: widthOfSubmenu,
      borderRadius: "8px",
      "& a": {
        textDecoration: "none",
        textAlign: "left",
        display: "flex",
      },
      "& a:first-of-type": {
        paddingBottom: "24px",
      },
    },
    widthInitial: {
      width: 'initial !important'
    },
    dropdownContentExists: {
      padding: "40px"
    },
    dropdownWithBorder: {
      border: "1px solid #DBE3EF",
    },
    username: {
      display: 'block',
      paddingBottom: '15px',
      fontFamily: 'Poppins',
      fontSize: '16px',
      lineHeight: '24px',
      color: "#6464FF",
    },
    linkForAccountMenu: {
      display: "flex"
    },
    icon: {
      paddingRight: "12px",
    },
  }));

  const classes = useStyles();


  return isAccountMenu ?

    <Grid container
          direction="row"
          className={`${classes.dropdownContentB2CAvatar} ${classes.widthInitial} ${widthOfSubmenu > 1 && classes.dropdownContentExists} ${widthOfSubmenu > 1 && classes.dropdownWithBorder}`}>
      <Grid container item key={reference} className={classes.fixedWidth}>

        <Grid
          container
          direction="column"
          className={classes.subheaderColumn}
        >
          { isLoggedIn ?

            <div>
              <a className={classes.linkForAccountMenu}
                 href={"/members"}>
                <img className={classes.icon} src={MembersArea}/>
                <Typography variant="body1"
                            className={classes.subheaderLinkChildB2CLogin}>Members area</Typography>
              </a>
              <a className={isAccountMenuB2C && classes.linkForAccountMenu}
                 href={"/logout"}>
                <img className={classes.icon} src={MemberLogout}/>
                <Typography variant="body1"
                            className={classes.subheaderLinkChildB2CLogin}>Logout</Typography>
              </a>
            </div>
          : <div>
          <a className={classes.linkForAccountMenu}
             href={"/login"}>
            <img className={classes.icon} src={LoginAvatar}/>
            <Typography variant="body1"
                        className={classes.subheaderLinkChildB2CLogin}>Login</Typography>
          </a>
          <a className={classes.linkForAccountMenu}
             href={"/sign-up"}>
            <img className={classes.icon} src={SignupIcon}/>
            <Typography variant="body1"
                        className={classes.subheaderLinkChildB2CLogin}>Join Boring Money</Typography>
          </a>
        </div>}
        </Grid>
      </Grid>
    </Grid>

    :

    <Grid container
          direction="row"
          className={`${classes.dropdownContent} ${widthOfSubmenu > 1 && classes.dropdownContentExists} ${widthOfSubmenu > 1 && classes.dropdownWithBorder}`}>
      {links.map(({text, reference, menuChildLinks}) => (

        <Grid container item key={reference} className={classes.fixedWidth}>

          <Grid
            container
            direction="column"
            className={classes.subheaderColumn}
          >
            {reference ?

              <div className={classes.subheaderLinkParent}>


                <ContainerForLinks
                  reference={reference}
                  _type={reference._type}
                >
                  <Typography variant="button" className={classes.subheaderLinkParent}>{text}</Typography>
                </ContainerForLinks></div> :
              <Typography variant="button" className={classes.subheaderLinkParent}>{text}</Typography>}

            <div>

              {menuChildLinks.map(({text, reference}) => (<>
                {reference ? <ContainerForLinks
                    reference={reference}
                    _type={reference._type}
                  >
                    <Typography variant="body1" className={classes.subheaderLinkChild}>{text}</Typography>
                  </ContainerForLinks> :
                  <Typography variant="body1" className={classes.subheaderLinkChild}>{text}</Typography>}
              </>))}
            </div>
          </Grid>
        </Grid>))}
    </Grid>
}
