import { useStaticQuery, graphql } from "gatsby";

function useCampusHeader() {
  const data = useStaticQuery(graphql`
    query HeadingQueryTESTCOURSE {
      sanityMenu(title: {eq: "Header Menu Campus"}) {
        title
        menuLinksTop {
          text
          reference {
            ... on SanityInformation {
              id
              slug {
                current
              }
              _type
              subfolder{
                folder
              }
            }
            ... on SanityLandingPage {
              slug {
                current
              }
              _type
              subfolder {
                folder
              }
            }
            ... on SanityLinkCategory {
              url
              _type
            }
            ... on SanityPost {
              _type
              slug {
                current
              }
            }
            ... on SanityQuestionPost {
              _type
              slug {
                current
              }
            }
            ... on SanityWidgetCategory {
              id
              title
              _type
            }
          }
          menuChildLinks {
            text
            reference {
              ... on SanityInformation {
                id
                slug {
                  current
                }
              }
              ... on SanityLandingPage {
                _type
                slug {
                  current
                }
                subfolder {
                  folder
                }
              }
              ... on SanityLinkCategory {
                url
                _type
              }
              ... on SanityPost {
                _type
                slug {
                  current
                }
              }
              ... on SanityQuestionPost {
                slug {
                  current
                }
                _type
              }
              ... on SanityWidgetCategory {
                id
                title
                _type
              }
            }
            menuChildLinks {
              text
              reference {
                ... on SanityWidgetCategory {
                  id
                  title
                  _type
                }
                ... on SanityInformation {
                  subfolder {
                    folder
                  }
                  slug {
                    current
                  }
                  _type
                }
                ... on SanityLinkCategory {
                  url
                  _type
                }
                ... on SanityPost {
                  slug {
                    current
                  }
                  _type
                }
                ... on SanityQuestionPost {
                  id
                  _type
                  slug {
                    current
                  }
                }
                ... on SanityLandingPage {
                  _type
                  slug {
                    current
                  }
                  subfolder {
                    folder
                  }
                }

              }
            }
          }
        }
      }
    }
  `);
  return data;
}

export default useCampusHeader
