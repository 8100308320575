import React, { useState, useEffect } from "react";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "../components/layout";
//import ExitIntent from "../utils/exitIntent";
import { getParameterByName } from "../lib/helpers";

const query = graphql`
  query SiteTitleQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
    }
  }

`;

function LayoutContainer(props) {
  const [showNav, setShowNav] = useState(false);

  function handleShowNav() {
    setShowNav(true);
  }

  function handleHideNav() {
    setShowNav(false);
  }

  const data = useStaticQuery(query);


  useEffect(() => {
    (async () => {
      try {

        await window.InitBraze();
        console.log("Braze - LAYOUT Init");
        var path = window.location.pathname;

        if (!path.includes("login")) {
          window.BrazeReferrer();
          window.BrazePageView();
          console.log("BrazePageView fired")
        }

      } catch (e) {
        console.log("err-BrazePageView", e)
      }

    })();
  }, []);

  if (!data.site) {
    throw new Error(
      'Missing "Site settings". Open the Studio at http://localhost:3333 and some content in "Site settings"'
    );
  }
  return (


    <Layout
      {...props}
      showNav={showNav}
      onHideNav={handleHideNav}
      onShowNav={handleShowNav}

      />


  );
}

export default LayoutContainer;
