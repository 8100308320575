import React from "react";

const CampusHamburger = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="16" cy="16" r="15.5625" stroke="#3C465F" stroke-width="0.875"/>
    <line x1="24" y1="10.6562" x2="8" y2="10.6562" stroke="#3C465F" stroke-width="1.3125"/>
    <line x1="24" y1="15.9897" x2="8" y2="15.9897" stroke="#3C465F" stroke-width="1.3125"/>
    <line x1="24" y1="21.3228" x2="8" y2="21.3228" stroke="#3C465F" stroke-width="1.3125"/>
  </svg>
);

export default CampusHamburger;
