import { useStaticQuery, graphql } from "gatsby";

function useCampusFooter() {
  const data = useStaticQuery(graphql`
    query CampusFooterQuery {
      sanityMenu(title: { eq: "Footer Menu Campus" }) {
        title
        menuLinksTop {
          text
          reference {
            ... on SanityInformation {
              id
              slug {
                current
              }
              subfolder {
                folder
              }
              _type
            }
            ... on SanityLandingPage {
              slug {
                current
              }
              _type
              subfolder {
                folder
              }
            }
            ... on SanityLinkCategory {
              url
              _type
            }
            ... on SanityPost {
              _type
              slug {
                current
              }
            }
            ... on SanityQuestionPost {
              _type
              slug {
                current
              }
            }
          }
        }
      }

      menuTop: sanityMenu(title: { eq: "Footer Top Menu Campus" }) {
        title
        menuLinksTop {
          text
          reference {
            ... on SanityInformation {
              id
              slug {
                current
              }
              subfolder {
                folder
              }
              _type
            }
            ... on SanityLandingPage {
              slug {
                current
              }
              _type
              subfolder {
                folder
              }
            }
            ... on SanityLinkCategory {
              url
              _type
            }
            ... on SanityPost {
              _type
              slug {
                current
              }
            }
            ... on SanityQuestionPost {
              _type
              slug {
                current
              }
            }
          }
        }
      }
    }
  `);
  return data;
}

export default useCampusFooter
