import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

import LogOut from "./LogOut" 

function LoginButton() {
  const {
    isAuthenticated,
    loginWithRedirect,
    user,
  } = useAuth0();

  const redirectUri = process.env.AUTH0_REDIRECT_URI;


  const handleLoginClick = () => {
    window.location.href = '/login';
  };

  return (
    <div className="mobile-nav-bar__buttons">
      {!isAuthenticated && (
        <div>
          <div><button onClick={handleLoginClick}>Log in</button></div>
        </div>
      )}
      {isAuthenticated && (
        <div>
          <div><a href="/members">Hi {user?.nickname}</a></div>
          <div><LogOut /></div>
        </div>
      )}
    </div>
  );
};

export default LoginButton;
