// extracted by mini-css-extract-plugin
export var clipPath = "headerTest-module--clipPath---NMa2";
export var container = "headerTest-module--container--q9SYU";
export var highlight = "headerTest-module--highlight--EySpz";
export var iconBox = "headerTest-module--iconBox--9RtUW";
export var menuItem = "headerTest-module--menuItem--N9bMp";
export var menuItemAccount = "headerTest-module--menuItemAccount--KZi2A";
export var menuItemAccountB2C = "headerTest-module--menuItemAccountB2C--6vgBm";
export var menuItemB2C = "headerTest-module--menuItemB2C--4yJMy";
export var menuItemCourse = "headerTest-module--menuItemCourse--MDzIL";
export var menuText = "headerTest-module--menuText--eaWEr";
export var subMenu = "headerTest-module--subMenu--NwiR6";
export var subMenuBottom = "headerTest-module--subMenu-bottom--S764+";
export var subMenuContainer = "headerTest-module--subMenuContainer--dIYh6";
export var subMenuHolder = "headerTest-module--subMenuHolder--pqd6x";
export var subMenuLast = "headerTest-module--subMenuLast--jR+4-";