import React, { Component } from 'react';
import auth0 from 'auth0-js';

const redirectUri = process.env.AUTH0_LOGOUTURI;
const domain = process.env.AUTH0_DOMAIN;
const clientID = process.env.AUTH0_CLIENTID;

const auth = new auth0.WebAuth({
  domain: domain,
  clientID: clientID,
  redirectUri: redirectUri,
  responseType: 'token id_token',
});

class LogoutButton extends Component {
  logout() {
    if (typeof window !== 'undefined') {
      localStorage.removeItem('access_token');
      localStorage.removeItem('id_token');
      localStorage.removeItem('expires_at');
      localStorage.removeItem('returnUrl');
      localStorage.removeItem('profile');
      localStorage.removeItem('custom_attr');
      localStorage.removeItem('genMkt');
      localStorage.removeItem('CaptureTemp');
      localStorage.removeItem('Email_Submission');
      sessionStorage.removeItem('profile2nd');

      window.brazeLogOut();
    }
   
    auth.logout({
      returnTo: redirectUri,
      clientID: clientID,
    });
  }

  render() {
    return (
      <button onClick={() => this.logout()}>
        Logout
      </button>
    );
  }
}

export default LogoutButton;
