import { Link, useStaticQuery, graphql } from "gatsby";
import React from "react";
import { connect } from "react-redux";
import Icon from "./icon";
import { getBlogUrl } from "../lib/helpers";
import * as styles from "./footer.module.css";
import { imageUrlFor } from "../lib/image-url";
import {
  AppBar,
  Typography,
  Toolbar,
  Grid,
  Hidden,
  List,
  ListItem,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import logo from "./icon/LogoLarge.svg";
import VisibleLogo from "./icon/VisibleFooterLogo.svg";
import logoSmall from "./icon/LogoSmall.svg";
import ContainerForLinks from "./containerForLinks";
import Container from "./container";
import Facebook from "./icon/socialMediaIcons/Facebook.svg";
import Instagram from "./icon/socialMediaIcons/Instagram.svg";
import LinkedIn from "./icon/socialMediaIcons/LinkedIn.svg";
import Twitter from "./icon/socialMediaIcons/Twitter.svg";
import X from './widgets/socialIconsWidget/img/X-Footer.svg'
import Youtube from "./icon/socialMediaIcons/Youtube.svg";
import VisibleFacebook from "./icon/socialMediaIcons/VisibleFacebook.svg";
import VisibleInstagram from "./icon/socialMediaIcons/VisibleInstagram.svg";
import VisibleLinkedIn from "./icon/socialMediaIcons/VisibleLinkedIn.svg";
import VisibleTwitter from "./icon/socialMediaIcons/VisibleTwitter.svg";
import VisibleYoutube from "./icon/socialMediaIcons/VisibleYoutube.svg";


import useCampusFooter from "../hooks/useCampusFooter";


const useStyles = makeStyles((theme) => ({
  footerText: {
    color: "#4E4B66",
    fontWeight: "400",
    letterSpacing: "0.05em",
    padding: "29px 27px 27px 0",
  },
  footerTextMobile: {
    color: "#2F2F4E",
    padding: "16px 0",
    fontWeight: "400",
  },
  visibleFooterText: {
    color: "#FFFFFF",
    fontWeight: "400",
    letterSpacing: "0.05em",
    padding: "29px 27px 27px 0",
  },
  visibleFooterTextMobile: {
    color: "#FFFFFF",
    padding: "16px 0",
    fontWeight: "400",
  },
  branding: {
    position: "absolute",
    top: "15px",
  },
  brandingSmall: {
    position: "absolute",
    top: "19px",
  },
  visibleBrandingSmall: {
    position: "absolute",
    top: "32px",
    zIndex: '99999999999'
  },
  appBar: {
    display: "flex",
    flexDirection: "column",
    boxShadow: "none",
    justifyContent: "flex-end",
    borderTop: "1px solid #DBE3EF",
    "@media (min-width:960px)": {
      justifyContent: "center",
    },
  },
  visibleAppBar: {
    backgroundColor: '#6464FF',
    display: "flex",
    flexDirection: "column",
    boxShadow: "none",
    justifyContent: "flex-end",
    "@media (min-width:960px)": {
      justifyContent: "center",
    },
  },
  appBarBlur: {
    display: "flex",
    flexDirection: "column",
    boxShadow: "none",
    filter: "blur(5px)",
    justifyContent: "flex-end",
    borderTop: "1px solid #DBE3EF",
    "@media (min-width:960px)": {
      justifyContent: "center",
    },
  },
  footerListItem: {
    color: "#4E4B66",
    fontWeight: 400,
    letterSpacing: "0.05em",
    width: "100%",
    padding: "21px 0 19px 0",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    textAlign: "center",
  },
  visibleFooterListItem: {
    color: "#FFFFFF",
    fontWeight: 400,
    letterSpacing: "0.05em",
    width: "100%",
    padding: "21px 0 19px 0",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    textAlign: "center",
  },
  visibleFooterListItemMobile: {
    color: "#FFFFFF",
    fontWeight: 500,
    lineHeight: "18px",
    fontSize: "12px",
    padding: "5px 0",
  },
  visibleFooterListItemTop: {
    color: "#FFFFFF",
    width: "100%",
    padding: "24px 16px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    textAlign: "center",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "24px",
    letterSpacing: "0em",
    textTransform: 'uppercase'
  },
  visibleFooterListItemTopMobile: {
    color: "#FFFFFF",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    textAlign: "center",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    letterSpacing: "0em",
    padding: "8px 0",
    textTransform: 'uppercase'
  },
  footerListItemMobile: {
    color: "#2F2F4E",
    fontWeight: 500,
    lineHeight: "18px",
    fontSize: "12px",
    padding: "5px 0",
  },
  footerListItemTop: {
    color: "#2F2F4E",
    width: "100%",
    padding: "24px 16px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    textAlign: "center",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "24px",
    letterSpacing: "0em",
  },
  footerListItemTopMobile: {
    color: "#2F2F4E",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    textAlign: "center",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    letterSpacing: "0em",
    padding: "8px 0",
  },
  footer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    height: "100%",
    paddingTop: "0",
    paddingBottom: "0",
  },
  twelve: {
    padding: "0px 12px",
    color: "#434A54",
  },
  footerMobile: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  bodyContainer: {
    maxWidth: "1170px"
  },
  bodyContainerTop: {
    maxWidth: "1170px",
    borderBottom: "1px solid #DBE3EF",
  },
  visibleBodyContainerTop: {
    maxWidth: '1170px',
    borderBottom: '1px solid #FFFFFF',
    position: 'relative'
  },
  toolbar: {
    padding: 0,
    width: "100%",
  },
  advertZone: {
    backgroundColor: "#F5F7FA",
    width: "100%",
    padding: "56px 0 56px 0",
  },
  linksInheriter: {
    textDecoration: "none",
    color: "inherit",
  },
  listPaddingMobile: {
    padding: "60px 0 0 0",
  },
  visibleListPaddingMobile: {
    padding: "100px 0 0 0",
  },
  bottomLinksMobile: {
    paddingTop: "20px",
    paddingBottom: "18px",
    borderBottom: "1px solid #DBE3EF",
  },
  visibleBottomLinksMobile: {
    paddingTop: "20px",
    paddingBottom: "18px",
    borderBottom: "1px solid #FFFFFF",
  },
  socialMediaLinksContainer: {
    padding: "16px 0",
    // //temporary, until we get tablet size design or footer content changes
    // [theme.breakpoints.down("1370")]: {
    //   padding: "0 0 16px 0",
    // },
  },
  socialMediaIcon: {
    padding: "0 8px",
  },
  firstSocialMediaIcon: {
    padding: "0 8px 0 0",
  },
  lastSocialMediaIcon: {
    padding: "0 0 0 8px",
  },
  socialMediaLinksContainerMobile: {
    padding: "23px 0 6px 0",
  },
  visibleLogoImageContainer: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    left: 0
  },
  visibleLogoImage: {
    display: 'block',
    height: '100%'
  }
}));

function Footer({ footer, onHideNav, onShowNav, showNav, isBlur, isCoursePage }) {
 // console.log('isCoursePage2: ', isCoursePage);

  const classes = useStyles();
  var data = useStaticQuery(graphql`
    query FooterQuery {
      sanityMenu(title: { eq: "Footer Menu" }) {
        title
        menuLinksTop {
          text
          reference {
            ... on SanityInformation {
              id
              slug {
                current
              }
              subfolder {
                folder
              }
              _type
            }
            ... on SanityLandingPage {
              slug {
                current
              }
              _type
              subfolder {
                folder
              }
            }
            ... on SanityLinkCategory {
              url
              _type
            }
            ... on SanityPost {
              _type
              slug {
                current
              }
            }
            ... on SanityQuestionPost {
              _type
              slug {
                current
              }
            }
          }
        }
      }

      menuTop: sanityMenu(title: { eq: "Footer Top Menu" }) {
        title
        menuLinksTop {
          text
          reference {
            ... on SanityInformation {
              id
              slug {
                current
              }
              subfolder {
                folder
              }
              _type
            }
            ... on SanityLandingPage {
              slug {
                current
              }
              _type
              subfolder {
                folder
              }
            }
            ... on SanityLinkCategory {
              url
              _type
            }
            ... on SanityPost {
              _type
              slug {
                current
              }
            }
            ... on SanityQuestionPost {
              _type
              slug {
                current
              }
            }
          }
        }
      }
    }
  `);
  const campusData = useCampusFooter();

  if (isCoursePage) {
    data = campusData;
  }

  return (
    <footer className="footer-container">
      <AppBar
        justify="center"
        alignItems="center"
        position="static"
        color="primary"
        className={isBlur ? classes.appBarBlur : (isCoursePage ? classes.visibleAppBar : classes.appBar)}
      >
        <Toolbar className={classes.toolbar}>
          <Hidden smDown implementation="js">
            <Grid container item alignItems="center" justify="space-between">
              <Hidden smDown implementation="js">
                <Grid item xs={1}></Grid>
              </Hidden>

              <Grid
                xs={10}
                container
                alignItems="center"
                justify="center"
                className={classes.bodyContainer}
                id="footer-container-desktop"
              >


                <Grid
                  container
                  item
                  className={isCoursePage ? classes.visibleBodyContainerTop : classes.bodyContainerTop}
                  alignItems="center"
                  justify="flex-end"

                >
                  {isCoursePage && <div className={classes.visibleLogoImageContainer}>
                    <Link to="/visible/members/">
                      <img src={isCoursePage ? VisibleLogo : logo} alt="Site Logo" className={classes.visibleLogoImage} />
                    </Link>
                  </div>}
                  <List className={classes.footer}>
                    {data.menuTop.menuLinksTop.map(
                      ({ text, reference }, index) => (
                        <div key={index}>
                          <Typography
                            variant="body1"
                            className={isCoursePage ? classes.visibleFooterListItemTop : classes.footerListItemTop}
                          >
                            <ContainerForLinks
                              reference={reference}
                              _type={reference._type}
                              customClassName={classes.linksInheriter}
                            >
                              {text}
                            </ContainerForLinks>
                          </Typography>
                        </div>
                      )
                    )}
                  </List>

                </Grid>

                <Grid
                  container
                  item
                  className={classes.bodyContainer}
                  xs={12}
                  alignItems="center"
                  justify="space-between"
                >
                  {!isCoursePage && <div className={classes.branding}>
                    <Link to="/">
                      <img src={isCoursePage ? VisibleLogo : logo} alt="Site Logo" />
                    </Link>
                  </div>}

                  <Grid container item xs={12} justify="space-between">
                    <List className={classes.footer}>
                      {" "}
                      <Typography
                        variant="subtitle2"
                        className={isCoursePage ? classes.visibleFooterText : classes.footerText}
                      >
                        © {new Date().getFullYear()} Boring Money. All rights
                        reserved
                      </Typography>
                      {data.sanityMenu.menuLinksTop.map(
                        ({ text, reference }, index) => (
                          <div key={index}>
                            <Typography
                              variant="subtitle2"
                              className={isCoursePage ? classes.visibleFooterListItem : classes.footerListItem}
                            >
                              <ContainerForLinks
                                reference={reference}
                                _type={reference._type}
                                customClassName={classes.linksInheriter}
                              >
                                {text}
                              </ContainerForLinks>

                              {data.sanityMenu.menuLinksTop[index + 1] ? (
                                <span className={classes.twelve}>|</span>
                              ) : (
                                  ""
                                )}
                            </Typography>
                          </div>
                        )
                      )}
                    </List>
                    <Grid item className={classes.socialMediaLinksContainer}>
                      <a
                        href="https://www.facebook.com/boringmoney/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={classes.firstSocialMediaIcon}
                      >
                        <img src={isCoursePage ? VisibleFacebook : Facebook} alt="Facebook" />
                      </a>
                      <a
                        href="https://www.instagram.com/boringmoney_hq/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={classes.socialMediaIcon}
                      >
                        <img src={isCoursePage ? VisibleInstagram : Instagram} alt="Instagram" />
                      </a>
                      <a
                        href="https://www.linkedin.com/company/boring-money/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={classes.socialMediaIcon}
                      >
                        <img src={isCoursePage ? VisibleLinkedIn : LinkedIn} alt="LinkedIn" />
                      </a>
                      <a
                        href="https://twitter.com/boringmoney"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={classes.socialMediaIcon}
                      >
                        <img src={isCoursePage ? VisibleTwitter : X} alt="X" />
                      </a>
                      <a
                        href="https://www.youtube.com/c/BoringMoneyHQ"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={classes.lastSocialMediaIcon}
                      >
                        <img src={isCoursePage ? VisibleYoutube : Youtube} alt="Youtube" />
                      </a>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Hidden smDown implementation="js">
                <Grid item xs={1}></Grid>
              </Hidden>
            </Grid>
          </Hidden>

          {/*--------------------------------------------------------------------------------------------------------------------------- */}
          <Hidden mdUp implementation="js">
            <Grid container item className={classes.footerMobile}>
              <Grid item xs={11} id="footer-container-mobile">
                <div className={isCoursePage ? classes.visibleBrandingSmall : classes.brandingSmall}>
                  <Link to={isCoursePage ? "/visible/members/" : "/"}>
                    <img src={isCoursePage ? VisibleLogo : logoSmall} alt="Site Logo" />
                  </Link>
                </div>
                <Grid container item alignItems="center">
                  <List className={isCoursePage ? classes.visibleListPaddingMobile : classes.listPaddingMobile}>
                    {data.menuTop.menuLinksTop.map(
                      ({ text, reference }, index) => (
                        <div key={index}>
                          <Typography
                            variant="body1"
                            className={isCoursePage ? classes.visibleFooterListItemTopMobile : classes.footerListItemTopMobile}
                          >
                            <ContainerForLinks
                              reference={reference}
                              _type={reference._type}
                              customClassName={classes.linksInheriter}
                            >
                              {text}
                            </ContainerForLinks>
                          </Typography>
                        </div>
                      )
                    )}
                  </List>
                </Grid>
                <Grid item className={classes.socialMediaLinksContainerMobile}>
                  <a
                    href="https://www.facebook.com/boringmoney/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.firstSocialMediaIcon}
                  >
                    <img src={isCoursePage ? VisibleFacebook : Facebook} alt="Facebook" />
                  </a>
                  <a
                    href="https://www.instagram.com/boringmoney_hq/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.socialMediaIcon}
                  >
                    <img src={isCoursePage ? VisibleInstagram : Instagram} alt="Instagram" />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/boring-money/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.socialMediaIcon}
                  >
                    <img src={isCoursePage ? VisibleLinkedIn : LinkedIn} alt="LinkedIn" />
                  </a>
                  <a
                    href="https://twitter.com/boringmoney"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.socialMediaIcon}
                  >
                    <img src={isCoursePage ? VisibleTwitter : X} alt="X" />
                  </a>
                  <a
                    href="https://www.youtube.com/c/BoringMoneyHQ"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.lastSocialMediaIcon}
                  >
                    <img src={isCoursePage ? VisibleYoutube : Youtube} alt="Youtube" />
                  </a>
                </Grid>
                <Grid
                  container
                  item
                  alignItems="center"
                  className={isCoursePage ? classes.visibleBottomLinksMobile : classes.bottomLinksMobile}
                >
                  {data.sanityMenu.menuLinksTop.map(
                    ({ text, reference }, index) => (
                      <Grid xs={6} key={index}>
                        <Typography
                          variant="subtitle2"
                          className={isCoursePage ? classes.visibleFooterListItemMobile : classes.footerListItemMobile}
                        >
                          <ContainerForLinks
                            reference={reference}
                            _type={reference._type}
                            customClassName={classes.linksInheriter}
                          >
                            {text}
                          </ContainerForLinks>
                        </Typography>
                      </Grid>
                    )
                  )}
                </Grid>
                <Grid container item alignItems="center">
                  <Typography
                    variant="subtitle2"
                    className={isCoursePage ? classes.visibleFooterTextMobile : classes.footerTextMobile}
                  >
                    © {new Date().getFullYear()} Boring Money. All rights
                    reserved
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Hidden>
        </Toolbar>
      </AppBar>
    </footer>
  );
}

export default connect(state => ({
  isBlur: state.blurReducer.isBlur
}), null)(Footer);
