import React from "react";
import HamburgerIcon from "./hamburger";
import CloseMenuIcon from "./closeMenu";
import CloseCourseMenu from "./closeCourseMenu";
import CampusHamburger from "./campusHamburger"

function Icon(props) {
  switch (props.symbol) {
    case "hamburger":
      return <HamburgerIcon />;
    case "closeMenu":
      return <CloseMenuIcon />;
    case "courseCloseMenu":
      return <CloseCourseMenu />;
    case "campusHamburger":
      return <CampusHamburger />
    default:
      return <span>Unknown icon: {props.symbol}</span>;
  }
}

export default Icon;
