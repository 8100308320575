// import { differenceInDays, formatDistance, format } from "date-fns";
// @ts-ignore
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Hidden, Typography, Dialog } from "@material-ui/core";
import Breadcrumb from "./landingPageComponents/blocks/breadcrumb";
import { Link } from "gatsby";
import ContainerForPages from "./containerForPages";
import Container from "./container";
import FooterDisclaimer from "./footer-disclaimer";
import SmallSpacerBlock from "./landingPageComponents/blocks/smallSpacerBlock";
import Lesson from "./coursePageComponents/lesson";
// @ts-ignore
import WhiteHamburger from "./icon/whitehamburger.svg";
// @ts-ignore
import OrangeBurger from "./icon/OrangeBurger.svg"
// @ts-ignore
import GreenBurger from "./icon/GreenHamburger.svg"
// @ts-ignore
import CompleteLessonCheck from "./icon/CompleteLessonCheck.svg";
// @ts-ignore
import CompleteLessonCheckMainSite from "./icon/CompleteLessonCheckMainSite.svg"
// @ts-ignore
import CompleteLessonCheckWhite from "./icon/CompleteLessonCheckWhite.svg";
// @ts-ignore
import CompleteLessonCheckMainSiteWhite from "./icon/CompleteLessonCheckMainSiteWhite.svg";


// @ts-ignore
import CloseIconBlack from "./icon/closeiconblack.svg";
import { getUserObject, getPageUrlOutOfSanityReference } from "../lib/helpers";
// @ts-ignore
import Squiggle1 from "./icon/campusSquiggles/Squiggle1.svg"
// @ts-ignore
import Squiggle1MainSite from "./icon/campusSquiggles/Squiggle1MainSite.svg"
// @ts-ignore
import Squiggle2 from "./icon/campusSquiggles/Squiggle2.svg"
// @ts-ignore
import Squiggle2MainSite from "./icon/campusSquiggles/Squiggle2MainSite.svg";
// @ts-ignore
import FacebookShareIcon from "./icon/socialMediaIcons/sharing/Facebook.svg";
// @ts-ignore
import FacebookShareIconMainSite from "./icon/socialMediaIcons/sharing/FacebookMain.svg"
// @ts-ignore
import LinkedinShareIcon from "./icon/socialMediaIcons/sharing/Linkedin.svg";
// @ts-ignore
import LinkedinShareIconMainSite from "./icon/socialMediaIcons/sharing/LinkedinMain.svg"
// @ts-ignore
import PinterestShareIcon from "./icon/socialMediaIcons/sharing/Pinterest.svg";
// @ts-ignore
import TwitterShareIcon from "./icon/socialMediaIcons/sharing/Twitter.svg";
// @ts-ignore
import TwitterShareIconMainSite from "./icon/socialMediaIcons/sharing/TwitterMain.svg"
// @ts-ignore
import WhatsappShareIcon from "./icon/socialMediaIcons/sharing/WhatsApp.svg";
// @ts-ignore
import WhatsappShareIconMain from "./icon/socialMediaIcons/sharing/WhatsAppMain.svg";
// @ts-ignore
import EmailShareIcon from "./icon/socialMediaIcons/sharing/Email.svg";
// @ts-ignore
import EmailShareIconMain from "./icon/socialMediaIcons/sharing/EmailMain.svg";

// @ts-ignore
import TitleSquiggle1 from "./icon/campusSquiggles/TitleSquiggle1.svg"
// @ts-ignore
import TitleSquiggle1MainSite from "./icon/campusSquiggles/TitleSquiggle1MainSite.svg"
import { buildImageObj, } from "../lib/helpers";

import { imageUrlFor } from "../lib/image-url";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CampusCTA from "./landingPageComponents/blocks/reuseableCampusComponents/campusCTA";

const useStyles = makeStyles((theme) => ({
  grey: {
    // marginTop: "15px",
    paddingBottom: "30px",
  },
  greyForAdviser: {
    paddingTop: "15px",
    paddingBottom: "30px",
    backgroundColor: "#F5F7FA",
  },
  fullWidth: {
    width: "100%",
    background: "rgba(56, 55, 122, 1)",
  },
  dropdownContainer: {
    [theme.breakpoints.down("sm")]: {
      // flexBasis: "100%",
      marginBottom: "8px",
      order: 2,
    },
    [theme.breakpoints.up("md")]: {
      minWidth: "100%",
      order: 2,
      gridRowStart: "Sidebar-top-start",
      gridRowEnd: "Sidebar-end",
      display: "flex",
      alignContent: "start",
      marginTop: "24px",
      marginBottom: "82px",
    },
  },
  courseContainer: {
    [theme.breakpoints.down("md")]: {
      // flexDirection: "column-reverse",
    },
    [theme.breakpoints.up("md")]: {
      paddingBottom: "76px",
      display: "grid",
      gridTemplateColumns: "1.5fr 0.7fr",
      gridTemplateRows: "max-content 1fr max-content",
      gap: "0px 30px",
      gridTemplateAreas: `
    "Content-top Sidebar-top"
    "Content-bottom Sidebar"
    "Content-buttons Sidebar-bottom"
    `,
    },
  },
  lessonLink: {
    backgroundColor: "#F7F5E8",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#3C465F",
    width: "100%",
    display: "flex",
    alignItems: "center",
    marginTop: 1,
    marginBottom: 1,
    padding: "0.875rem 1rem 0.875rem 1rem",
  },
  lessonLinkMainSite: {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#2F2F4E",
    width: "100%",
    display: "flex",
    alignItems: "center",
    marginTop: 2,
    marginBottom: 2,
    padding: "0.875rem 1rem 0.875rem 1rem",
    background: "#FFFFFF",
    boxShadow: "0px 0px 1px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05)",
  },
  lessonLinkContainer: {
    width: "100%",
  },
  backdrop: {
    position: "relative",
    width: "100%",
    // top: "50%",
    // top: "52px",
    left: "50%",
    transform: "translate(-50%, 0)",
    zIndex: 999,
    [theme.breakpoints.down("xs")]: {
      // width: 345,
      // padding: "16px",
    },
    [theme.breakpoints.down(345)]: {
      // width: 300,
      // padding: "16px",
    },
  },
  advContainerForm: {
    borderRadius: "8px",
    width: "220px",
    [theme.breakpoints.down("lg")]: {
      // maxWidth: "150px",
      // maxHeight: "150px",
      width: "100%",
    },
  },
  hamburgerIcon: {
    marginLeft: "auto",
  },
  lessonSpan: {
    width: "100%",
    display: "inline-flex",
  },
  completeLessonSpan: {
    width: "100%",
    backgroundImage: `url(${CompleteLessonCheck})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '100%'
  },
  positionHolder: {
    height: "52px",
    width: "100%",
  },
  buttonContainer: {
    paddingTop: "1rem",
    // paddingBottom: "50px",
    borderTop: "1px solid rgba(255, 255, 255, 0.2)",
    flexWrap: "nowrap",
    gridArea: "Content-buttons",
    // height: "52px",
    [theme.breakpoints.up("md")]: {
      // paddingBottom: "82px",
      paddingTop: "40px",
    },
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridTemplateRows: "max-content",
    gap: "1em",
  },
  backButton: {
    border: "1px solid #3C465F",
    padding: "18px",
    backgroundColor: "inherit",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "20px",
    color: "#3C465F",
    letterSpacing: "0.04em",
    textTransform: "uppercase",
    marginRight: "0.5rem",
    cursor: "pointer",
  },
  backButtonMainSite: {
    border: "1px solid #94A4D6",
    borderRadius: "8px",
    padding: "18px",
    backgroundColor: "inherit",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "20px",
    letterSpacing: "0.04em",
    height: "58px",
    backgroundColor: "#FFFFFF",
    textTransform: "uppercase",
    marginRight: "0.5rem",
    cursor: "pointer",
    color: "#3C455C",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "24px",
  },
  courseTitle: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    marginTop: "24px",
    fontWeight: 700,
    fontSize: "28px",
    lineHeight: "42px",
    color: "#6464F6",
  },
  courseTitleMainSite: {
    fontStyle: "normal",
    marginTop: "24px",
    fontWeight: 700,
    fontSize: "48px",
    lineHeight: "58px",
    letterSpacing: "-2px",
    color: "#262641",
    [theme.breakpoints.down("md")]:{
      fontSize: "32px",
      lineHeight: "40px",
    }
  },
  titleContainer: {
    [theme.breakpoints.down("sm")]: {
      order: 1,
    },
    [theme.breakpoints.up("md")]: {
      order: 1,
      gridArea: "Content-top",
      marginBottom: "40px",
    },
  },
  contentContainer: {
    [theme.breakpoints.down("sm")]: {
      order: 3,
      paddingBottom: "40px",
    },
    [theme.breakpoints.up("md")]: {
      order: 3,
      gridArea: "Content-bottom",
      minWidth: "100%",

    },
  },
  contentContainerDropdownOpen: {
    [theme.breakpoints.down("sm")]: {
      order: 3,
    },
    opacity: 0.8,
    // "&::before": {

    // background: "#292858",
    //   content: " ",
    //   width: "100vw",
    //   backgroundSize: "cover",
    // },
  },
  titleButtonContainer: {
    gap: "12px",
    marginTop: "12px",
    marginBottom: "32px",
    [theme.breakpoints.up("md")]: {
      marginBottom: "40px",
    },
  },
  titleButton: {
    fontFamily: "inherit",
    textDecoration: "none",
    color: "#FFFFFF",
    backgroundColor: "inherit",
    padding: "6px 24px",
    border: "1px solid #FFFFFF",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "16px",
    textTransform: "uppercase",
    cursor: "pointer",
  },
  desktopLessonContainer: {
    cursor: "pointer",
  },
  squiggle1: {
    [theme.breakpoints.down(960)]: {
      paddingTop: "26px",
      width: "100%",
      maxHeight: "120px",
    }
  },
  squiggle2: {
    [theme.breakpoints.down(960)]: {
      width: "100%",
      maxHeight: "120px",
    }
  },
  squiggleContainer: {
    width: "100%",
    // paddingBottom: "50px",
    [theme.breakpoints.up("md")]: {
      paddingBottom: "70px",
    },
  },
  squiggleContainerBottom: {
    width: "100%",
    // position: "relative",
    bottom: "30%",
    // paddingBottom: "50px",
    [theme.breakpoints.up("md")]: {
      marginBottom: "70px",
    },
  },
  squiggleImgHolder: {
  },
  titleSquiggle1: {
    position: "absolute",
    marginTop: "25px",
    marginLeft: "-130px",
    [theme.breakpoints.down(900)]: {
      display: "none",
    },
  },
  sponsorContainer: {
    padding: "8px",
    justifyContent: "center",
    // width: "190px",
    //   height: "140px",
    [theme.breakpoints.up("md")]: {
      // position: "relative",
      // top: "50%",
      gridArea: "Sidebar-bottom",
      // bottom: "50%",
      padding: "40px 8px 8px 40px",
      alignContent: "flex-start",
    },
    // height: "140px",
    // width: "190px",
    // boxSizing: "content-box",
  },
  sponsorImage: {
    width: "190px",
    height: "140px",
  },
  sponsorAnchor: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      marginRight: "auto",
    }
  },
  sharingLabel: {
    fontFamily: 'Poppins',
    color: '#3C465F',
    textTransform: 'uppercase',
    fontSize: '16px',
    lineHeight: '20px',
    display: 'block',
    paddingTop: '1.5em',
    paddingBottom: '.8em',
    fontWeight: 600
  },

  sharingLabelMainSite: {
    fontFamily: 'Poppins',
    color: "#65689F",
    textTransform: 'uppercase',
    fontSize: '16px',
    lineHeight: '20px',
    display: 'block',
    paddingTop: '1.5em',
    paddingBottom: '.8em',
    fontWeight: 600
  },
  textAlignCenter: {
    textAlign: 'center'
  },
  sharingAnchor: {
    display: 'block',
    paddingLeft: '.6em',
    paddingRight: '.6em',
    [theme.breakpoints.down(1160)]: {
      paddingLeft: '.5em',
      paddingRight: '.5em'
    },
    [theme.breakpoints.down(1080)]: {
      paddingLeft: '.4em',
      paddingRight: '.4em'
    },
    [theme.breakpoints.down(1010)]: {
      paddingLeft: '.3em',
      paddingRight: '.3em'
    },
    [theme.breakpoints.down(959)]: {
      paddingLeft: '.6em',
      paddingRight: '.6em'
    }
  },
  mobileSharingContainer: {
    borderTop: "1px solid rgba(255, 255, 255, 0.2)",
    borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
    marginTop: '1.5em',
    paddingBottom: '1.5em'
  },
  completedIcon: {
    marginLeft: "auto",
  },
  nextButton: {
    height: "52px",
    background: "#FF0F5F",
    padding: "18px",
    border: "none",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "20px",
    color: "#FFFFFF",
    letterSpacing: "0.04em",
    textTransform: "uppercase",
    marginLeft: "0.5rem",
    cursor: "pointer",
  },

  activeLessonLink: {
    backgroundColor: "#6464FF",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#FFFFFF",
    width: "100%",
    display: "flex",
    alignItems: "center",
    marginTop: 1,
    marginBottom: 1,
    padding: "0.875rem 1rem 0.875rem 1rem",
  },
  activeLessonLinkMainSite: {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#FFFFFF",
    width: "100%",
    display: "flex",
    alignItems: "center",
    marginTop: 2,
    marginBottom: 2,
    padding: "0.875rem 1rem 0.875rem 1rem",
    background: "#3C465F",
    boxShadow: "0px 0px 1px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05)",
  },
  hidden: {
    display: 'none !important'
  }
}));

var apiURL = process.env.GATSBY_QUESTIONAPI;

type CoursePageProps = {
  title: string;
  slug: {
    _key?: string;
    _type: string;
    current: string;
  };
  subfolder: {
    folder: string;
  };
  _id: string;
  subtitle?: string;
  redirectPage: object;
  courseColour: string;
  content: {
    _key: string;
    _type: string;
    lessonItems: object[];
    title: string;
    lessonID: object[];
  }[];
  sponsor?: {
    courseSponsorName: string;
    courseSponsorLink: string,
    _rawCourseSponsorImg: any;
  },
  facebookUrl: string;
  linkedinUrl: string;
  twitterUrl: string;
  whatsappUrl: string;
  pinterestUrl: string;
  mailUrl: string;
  enableOpenCourse: string;
  theme: string;
  isCoursePage: boolean;
};

const RenderLessons = ({
  lessonItems,
  lessonTitle,
  currentLesson,
  title,
  index,
  _key,
  setActiveLessonName,
  setActiveLessonKey,
  courseColour,
  courseId,
  lessonID,
  isCoursePage
  }) => {
  const getActiveLessonStatus = (index, currentLesson) => {
    if (index == currentLesson) {
      return true;
    } else {
      return false;
    }
  };

 //console.log("render", lessonID.current)
  var currentLessonID = _key;

  if (lessonID && lessonID.current) {
    currentLessonID = lessonID.current;
  }


  let activeLesson = getActiveLessonStatus(index, currentLesson);

  let numPages = lessonItems.length;

  const [activePage, setActivePage] = useState();

  if (activeLesson) {
    setActiveLessonName(lessonTitle);
    setActiveLessonKey(currentLessonID);
  }

  if (numPages > 1) {
    return (
      <>
        {activeLesson &&
          lessonItems.map(({ _rawIntroPara, title }, index) => (

            <>
              <Lesson
                title={title}
                isActiveLesson={activeLesson}
                rawIntroPara={_rawIntroPara}
                courseColour={courseColour}
                isCoursePage={isCoursePage}
              />
            </>
          ))}
      </>
    );
  }

  return (
    <>
      {lessonItems.map(({ _rawIntroPara, title }) => (
        <Lesson
          title={title}
          isActiveLesson={activeLesson}
          rawIntroPara={_rawIntroPara}
          courseColour={courseColour}
          isCoursePage={isCoursePage}
        />
      ))}
    </>
  );
};

function CoursePage(props: CoursePageProps): JSX.Element {
  const { title, content, slug, subfolder, _id, redirectPage, courseColour, sponsor, facebookUrl, twitterUrl, pinterestUrl, whatsappUrl, linkedinUrl, mailUrl, enableOpenCourse, theme, isCoursePage, courseName } = props;
  // const { _rawCourseSponsorImg, courseSponsorLink, courseSponsorName } = props.sponsor;
  const classes = useStyles();
  const [currentLesson, setCurrentLesson] = useState(0);
  const [currentLessonCompleted, setCurrentLessonCompleted] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [activeLessonName, setActiveLessonName] = useState<string>();
  const [activeLessonKey, setActiveLessonKey] = useState<string>();
  const [lessonsMarkedAsCompleted, setLessonsMarkedAsCompleted] = useState([]);
  const [shouldRedirectToLoginPage, setShouldRedirectToLoginPage] = useState(false);
  const [shouldRedirectToCompletePage, setShouldRedirectToCompletePage] = useState(false);
  const [isUserAuthenticated, setIsUserAuthenticated] = useState(false);
  const [emailConfirmed, setEmailConfirmed] = useState(false);
  const [sidebarEntries, setSidebarEntries] = useState([]);
  const [shouldRefreshCompleteLessons, setShouldRefreshCompleteLessons] = useState(false);
  const matchesMobile = useMediaQuery('(max-width: 960px)')
  const [token, setToken] = useState(null);
  const [userId, setUserId] = useState(null);
  const [triggerStartCourse, setTriggerStartCourse] = useState(false);
  const [hideNextButton, setHideNextButton] = useState(false);
  const [allCompleteLessonIds, setAllCompleteLessonIds] = useState()
  const [courseStatus, setCourseStatus] = useState();
  const [courseCompleted, setCourseCompleted] = useState(false);


  if (theme!=null) {
   // console.log("theme", theme)
  }

  if (enableOpenCourse!=null) {
    //console.log("enableOpenCourse", enableOpenCourse)
  }

  useEffect(() => {

    (async () => {
      setShouldRefreshCompleteLessons(true);

      if (enableOpenCourse) {
        setEmailConfirmed(true);
        setIsUserAuthenticated(true);

      } else {

        window.addEventListener("storage", onStorageUpdate);

        //var token = window.localStorage.getItem("token");

        //setToken(token);

        //var userObject = await getUserObject(token);

        //if (!userObject.IsAuthenticated) {
        //  window.location.href = '/visible/login?returnUrl=' + encodeURIComponent(window.location.pathname + window.location.search);
        //} else {
        //  if (!userObject.EmailConfirmed) {
        //     window.location.href = '/visible/verify-email';
        //  } else {
            setEmailConfirmed(true);
        //  }

          setIsUserAuthenticated(true);
         // setUserId(userObject.Id);
       // }
      }


    })();
  }, []);

  const getCourseStatus = async () => {
    var model = {
      CourseId: _id,
      UserId: userId,
    }

    await fetch(process.env.GATSBY_QUESTIONAPI + '/CourseStatus/CourseStatus', {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + token,
        'Accept': '*/*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(model)
    })
      .then((response) => response.json())
      .then((responseJson) => {
        setCourseStatus(responseJson.Result)
        // const buttonState = {
        //   courseStatus: responseJson.Result,
        //     id: courseDetails.CourseId}
        // };
      });

  }

  useEffect(() => {
    (async () => {

      if ((!enableOpenCourse) || (enableOpenCourse == null)) {
        if (userId) {
          getCourseStatus();
        }
      }
    })();
  }, [_id, userId]);

  useEffect(() => {
    let isMounted = true;

    (async () => {
      window.addEventListener('message', async function (e) {
        var data = e.data;

        if (isJsonObject(data)) {
          data = JSON.parse(data);
        }

        if (data.isQuizComplete && data.isQuizComplete === 'true' && currentLesson === totalLessons && isMounted) {

            setCourseCompleted(true)
            await markCourseAsComplete();
            setShouldRedirectToCompletePage(true)

          //Braze Logging


          return false;
        }

        return;
      });
    })();

    return () => {
      isMounted = false;
    }
  });


  useEffect(() => {

   if (courseCompleted){
     try {
       if (courseStatus !== "Completed"){
        // console.log("Event fire for course complete")
         window.visibleCourseCompleted(_id);
       }
     } catch (e) {
     }
   }
  }, [courseCompleted])

  function isJsonObject(strData) {
    try {
      JSON.parse(strData);
    } catch (e) {
      return false;
    }
    return true;
  }

  useEffect(() => {
    (async () => {
      if (triggerStartCourse) {
        // console.log('start course');


        try {
          if (courseStatus !== "Started") {
            window.visibleCourseStarted(_id)
          }
        } catch (e) {

        }

        if (enableOpenCourse) {
          setEmailConfirmed(true);
          setIsUserAuthenticated(true);

        } else {

          var token = window.localStorage.getItem("token");
          var userObject = await getUserObject(token);

          if (!userObject.IsAuthenticated) {
            // window.location.href = '/visible/login?returnUrl=' + encodeURIComponent(window.location.pathname + window.location.search);
          } else {
            await markCourseAsStarted(userObject.Id, token);
          }
        }
      }
    })();
  }, [triggerStartCourse]);

  useEffect(() => {
    (async () => {
      if (shouldRefreshCompleteLessons) {
        var completeLessonIds = await getAllCompleteLessonIds();
        setAllCompleteLessonIds(completeLessonIds);
        var sidebarEntries = new Array();

        content.forEach(function (element) {
          var sidebarEntry = {
            title: element.title,
            isComplete: completeLessonIds.includes(element.lessonID.current)
          }

          sidebarEntries.push(sidebarEntry);
        });

        setSidebarEntries(sidebarEntries);

        setShouldRefreshCompleteLessons(false);
      }
    })();
  }, [shouldRefreshCompleteLessons]);

  useEffect(() => {
    if (enableOpenCourse) {
      setEmailConfirmed(true);
      setIsUserAuthenticated(true);

    } else {
      if (shouldRedirectToLoginPage) {
        window.location.href = '/visible/login?returnUrl=' + encodeURIComponent(window.location.pathname + window.location.search);
      }
    }
  }, [shouldRedirectToLoginPage]);

  useEffect(() => {
    if (shouldRedirectToCompletePage) {
      var redirectUrl = getPageUrlOutOfSanityReference(redirectPage);

      window.location.href = redirectUrl;
    }
  }, [shouldRedirectToCompletePage]);

  var onStorageUpdate = (e) => {
    const { key, newValue } = e;

    if (key === "isUserAuthenticated") {
      var isAuthenticated = newValue === 'true' ? true : false;

      if (!isAuthenticated) {
        setShouldRedirectToLoginPage(true);
      }
    }
  };

  const getAllCompleteLessonIds = async () => {
    var model = {
      CourseId: _id,
      UserId: localStorage.getItem("UserId")
    }

    var result = await fetch(process.env.GATSBY_QUESTIONAPI + '/AllCompleteLessons/AllIds', {
      method: 'POST',
      headers: {
        'Accept': '*/*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(model)
    })
      .then((response) => response.json())
      .then((responseJson) => { return responseJson });

    if (!result || !result.Success) {
      return [];
    } else {
      return result.Result;
    }
  }

  // total number of lessons in course minus 1 to match index numbers
  let totalLessons = content.length - 1;

  // Regex to remove beginning numbers of lesson titles for use as page title


  const handleNext = async () => {
    if (currentLesson === 0) {
      setTriggerStartCourse(true);
    }

    //console.log("activeLessonKey", activeLessonKey, _id)

    if (currentLesson < totalLessons) {
      if (!lessonsMarkedAsCompleted.includes(activeLessonKey)) {
        var lessonsMarked = lessonsMarkedAsCompleted;

        lessonsMarked.push(activeLessonKey);

        //console.log("activeLessonKey2", activeLessonKey, _id)

        setLessonsMarkedAsCompleted(lessonsMarked);

        await markLessonAsComplete();

        try {
          window.visibleLessonCompleted(_id, activeLessonKey);
        } catch (e) {

        }
      }

      setShouldRefreshCompleteLessons(true);

      setCurrentLesson(currentLesson + 1);
    }

    if ((currentLesson + 1) === totalLessons) {
      setHideNextButton(true);

    } else {
      setHideNextButton(false);
    }

    window.scrollTo(0, 0)
  };

  const handleBack = () => {
    setHideNextButton(false);

    if (currentLesson >= 1) {
      setCurrentLesson(currentLesson - 1);
    }
    window.scrollTo(0, 0)
  };

  const markLessonAsComplete = async () => {
    var model = {
      CourseId: _id,
      UserId: userId,
      LessonId: activeLessonKey
    }
    await fetch(process.env.GATSBY_QUESTIONAPI + '/MarkLessonAsCompleted/MarkLessonAsCompleted', {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + token,
        'Accept': '*/*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(model)
    })
      .then((response) => response)
      .then((responseJson) => { return responseJson });
  }

  const markCourseAsComplete = async () => {
    var model = {
      CourseId: _id,
      UserId: userId
    }

    await fetch(process.env.GATSBY_QUESTIONAPI + '/MarkCourseAsComplete/MarkCourseAsComplete', {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + token,
        'Accept': '*/*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(model)
    })
      .then((response) => response)
      .then((responseJson) => { return responseJson });
  }

  const markCourseAsStarted = async (userId, token) => {
    var model = {
      CourseId: _id,
      UserId: userId,
    };

    await fetch(
      process.env.GATSBY_QUESTIONAPI +
      "/MarkCourseAsStarted/MarkCourseAsStarted",
      {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
          Accept: "*/*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(model),
      }
    )
      .then((response) => response)
      .then((responseJson) => {
        return responseJson;
      }).catch((err) => {
        console.log("Rejected", err.message)
      });
  };

  const greenSpecificStyles = makeStyles((theme) => ({

    activeLessonLink: {
      backgroundColor: "#6464F6",
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "24px",
      color: "#FFFFFF",
      width: "100%",
      display: "flex",
      alignItems: "center",
      marginTop: 1,
      marginBottom: 1,
      padding: "0.875rem 1rem 0.875rem 1rem",
    },
    nextButton: {
      height: "52px",
      background: "#EA3762",
      padding: "12px",
      border: "none",
      fontWeight: 500,
      fontSize: "18px",
      lineHeight: "27px",
      color: "#FFFFFF",
      letterSpacing: "0.04em",
      textTransform: "uppercase",
      marginLeft: "0.5rem",
      cursor: "pointer",
    },
  }))

  const redSpecificStyles = makeStyles((theme) => ({

    activeLessonLink: {
      backgroundColor: "#6464F6",
      // height: "52px",
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "24px",
      color: "#FFFFFF",
      width: "100%",
      display: "flex",
      alignItems: "center",
      marginTop: 1,
      marginBottom: 1,
      // paddingLeft: "1rem",
      // paddingRight: "1rem",
      // padding: "1rem",
      padding: "0.875rem 1rem 0.875rem 1rem",
    },
    nextButton: {
      height: "52px",
      background: "#EA3762",
      padding: "12px",
      border: "none",
      fontWeight: 500,
      fontSize: "18px",
      lineHeight: "27px",
      color: "#FFFFFF",
      letterSpacing: "0.04em",
      textTransform: "uppercase",
      marginLeft: "0.5rem",
      cursor: "pointer",
    },
  }))

  const orangeSpecificStyles = makeStyles((theme) => ({

    activeLessonLink: {
      backgroundColor: "#6464F6",
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "24px",
      color: "#FFFFFF",
      width: "100%",
      display: "flex",
      alignItems: "center",
      marginTop: 1,
      marginBottom: 1,
      padding: "0.875rem 1rem 0.875rem 1rem",
    },
    nextButton: {
      height: "52px",
      background: "#EA3762",
      padding: "12px",
      border: "none",
      fontWeight: 500,
      fontSize: "18px",
      lineHeight: "27px",
      color: "#FFFFFF",
      letterSpacing: "0.04em",
      textTransform: "uppercase",
      marginLeft: "0.5rem",
      cursor: "pointer",
    },
  }))



  const Hamburger = WhiteHamburger


  const squiggleDecider = () => {

    const num = Math.floor(Math.random() * 3)
    switch (num) {
      case 0:
        return null
      case 1:
        return <img className={classes.squiggle1} src={isCoursePage ? Squiggle1 : Squiggle1MainSite} />;
      case 2:
        return <img className={classes.squiggle2} src={isCoursePage ? Squiggle2 : Squiggle2MainSite} />;
    }
  }


  const squiggleTitleDecider = () => {

    const num = Math.floor(Math.random() * 3)
    switch (num) {
      case 0:
        return null
      case 1:
        return <img className={classes.titleSquiggle1} src={isCoursePage ? TitleSquiggle1 : TitleSquiggle1MainSite} />;
      case 2:
    }
  }

  const decideStartingCourse = (index) => {
    if (index === 1) {
      setTriggerStartCourse(true);
    }

    if (index === totalLessons) {
      setHideNextButton(true);
    } else {
      setHideNextButton(false);
    }
  }


  return (
    <article>
      {isUserAuthenticated && emailConfirmed && <ContainerForPages isCoursePage={isCoursePage} dropdownOpen={dropdownOpen}>
        <Container customClassName={undefined} customBodyClass={undefined}>
          <Grid container className={classes.courseContainer}>
            <Grid
              item
              container
              xs={12}
              className={classes.titleContainer}
              md={12}
            >
              <Grid item xs={12}>
                <Typography className={isCoursePage ? classes.courseTitle : classes.courseTitleMainSite} variant="h3">
                  {squiggleTitleDecider()}
                  {activeLessonName && activeLessonName}
                </Typography>
              </Grid>
              {/* @ts-ignore */}

            </Grid>
            <Grid
              item
              container
              xs={12}
              md={8}
              className={
                dropdownOpen
                  ? classes.contentContainerDropdownOpen
                  : classes.contentContainer
              }
            >
              {content.map(({ _type, title, lessonTitle, _key, lessonItems, lessonID }, index) => (
                <>
                  {_type == "lesson" && (
                    <RenderLessons
                      title={title}
                      lessonItems={lessonItems}
                      lessonTitle={lessonTitle}
                      currentLesson={currentLesson}
                      index={index}
                      _key={_key}
                      setActiveLessonName={setActiveLessonName}
                      setActiveLessonKey={setActiveLessonKey}
                      courseColour={courseColour}
                      courseId={_id}
                      lessonID={lessonID}
                      isCoursePage={isCoursePage}
                    />
                  )}
                </>
              ))}

              {matchesMobile &&
                <Grid container className={classes.buttonContainer}>
                  {
                    currentLesson !== 0 ?
                      <Grid
                        item
                        xs={12}
                        md={6}
                        component="button"
                        className={isCoursePage ? classes.backButton : classes.backButtonMainSite}
                        onClick={() => handleBack()}
                      >
                        BACK
                      </Grid>
                      : <Grid item xs={12} md={6}></Grid>
                }

                {
                  !hideNextButton && <CampusCTA customBorderRadius={!isCoursePage && "8px"} linkText={(currentLesson === totalLessons) ? 'COMPLETE' : 'NEXT'} buttonType={"rectangular"} arrowIcon={false} pageType={"available courses"}
                  customClickEvent={() => handleNext()} />
                }
                  {/*<Grid*/}
                  {/*  item*/}
                  {/*  xs={12}*/}
                  {/*  md={6}*/}
                  {/*  component="button"*/}
                  {/*  className={`${classes.nextButton} ${hideNextButton ? classes.hidden : ''}`}*/}
                  {/*  onClick={() => handleNext()}*/}
                  {/*>*/}
                  {/*  {(currentLesson === totalLessons) ? 'COMPLETE' : 'NEXT'}*/}

                  {/*</Grid>*/}
                </Grid>
              }
              {matchesMobile &&
                <Grid container className={classes.squiggleContainer} item xs={12}>
                  <Grid item xs={6}></Grid>
                  <Grid item xs={6} className={classes.squiggleImgHolder} justify={"center"}
                    container>{squiggleDecider()}</Grid>
                </Grid>
              }
              {
                (facebookUrl || twitterUrl || linkedinUrl || whatsappUrl || mailUrl || pinterestUrl) &&
                <Hidden mdUp>

                  <Grid container className={classes.mobileSharingContainer}>
                    <Grid item xs={12} className={classes.textAlignCenter}>
                      <label className={isCoursePage ? classes.sharingLabel : classes.sharingLabelMainSite}>SHARE THIS COURSE</label>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container alignItems="center" justify="center">
                        {facebookUrl && <Grid item>
                          <a href={facebookUrl} target="_blank" className={classes.sharingAnchor}>
                            <img src={isCoursePage ? FacebookShareIcon : FacebookShareIconMainSite} />
                          </a>
                        </Grid>}
                        {twitterUrl && <Grid item>
                          <a href={twitterUrl} target="_blank" className={classes.sharingAnchor}>
                            <img src={isCoursePage ? TwitterShareIcon : TwitterShareIconMainSite} />
                          </a>
                        </Grid>}
                        {linkedinUrl && <Grid item>
                          <a href={linkedinUrl} target="_blank" className={classes.sharingAnchor}>
                            <img src={isCoursePage ? LinkedinShareIcon : LinkedinShareIconMainSite} />
                          </a>
                        </Grid>}
                        {pinterestUrl && <Grid item>
                          <a href={pinterestUrl} target="_blank" className={classes.sharingAnchor}>
                            <img src={PinterestShareIcon} />
                          </a>
                        </Grid>}
                        {whatsappUrl && <Grid item>
                          <a href={whatsappUrl} target="_blank" className={classes.sharingAnchor}>
                            <img src={isCoursePage ? WhatsappShareIcon : WhatsappShareIconMain} />
                          </a>
                        </Grid>}
                        {mailUrl && <Grid item>
                          <a href={mailUrl} target="_blank" className={classes.sharingAnchor}>
                            <img src={isCoursePage ? EmailShareIcon : EmailShareIconMain} />
                          </a>
                        </Grid>}
                      </Grid>
                    </Grid>
                  </Grid>
                </Hidden>
              }
              {sponsor && sponsor.courseSponsorName &&
                <Hidden mdUp>
                  <Grid container item className={classes.sponsorContainer}>
                    <a href={sponsor.courseSponsorLink} target={"_blank"}>
                      <img src={imageUrlFor(buildImageObj(sponsor._rawCourseSponsorImgDark))
                        .fit("scale")
                        .auto("format")
                        .url()}
                        alt={sponsor.courseSponsorName}
                        className={classes.logoImage}
                      />
                    </a>
                    {/*<button onClick={() => console.log(_rawCourseSponsorImg, courseSponsorName, courseSponsorLink)}>props for img</button>*/}
                  </Grid>
                </Hidden>
              }
            </Grid>
            <Grid
              container
              xs={12}
              md={4}
              className={classes.dropdownContainer}
            >
              {/* @ts-ignore */}
              <Hidden mdUp>
                {!dropdownOpen &&
                  sidebarEntries.map(
                    ({ title, isComplete }, index) =>
                      index == currentLesson && (
                        <Grid
                          item
                          className={
                            index == currentLesson
                              ? (isCoursePage ? classes.activeLessonLink : classes.activeLessonLinkMainSite)
                              : (isCoursePage ? classes.lessonLink : classes.lessonLinkMainSite)
                          }
                          onClick={() => { decideStartingCourse(index); setCurrentLesson(index) }}
                        >
                          {title}{" "}
                          <img
                            className={classes.hamburgerIcon}
                            src={Hamburger}
                            onClick={() => setDropdownOpen(true)}
                          />
                        </Grid>
                      )
                  )}
                {dropdownOpen && (
                  <div className={classes.positionHolder}>
                    <Grid container className={classes.backdrop}>
                      <Grid
                        item
                        className={
                          (isCoursePage ? classes.lessonLink : classes.lessonLinkMainSite)
                        }
                      >
                        <span
                          className={classes.lessonSpan}
                          onClick={() => setDropdownOpen(false)}
                        >
                          {'Close'}
                        </span>{" "}

                        <img
                          className={classes.hamburgerIcon}
                          src={CloseIconBlack}
                          onClick={() => setDropdownOpen(false)}
                        />
                      </Grid>
                      {sidebarEntries.map(
                        ({ title, isComplete }, index) => (
                          <Grid
                            item
                            className={
                              index == currentLesson
                                ? (isCoursePage ? classes.activeLessonLink : classes.activeLessonLinkMainSite)
                                : (isCoursePage ? classes.lessonLink : classes.lessonLinkMainSite)
                            }
                          >
                            <span
                              className={classes.lessonSpan}
                              onClick={() => { decideStartingCourse(index); setCurrentLesson(index); }}
                            >
                              {title}  {isComplete && <img className={classes.completedIcon} src={index == currentLesson ? (isCoursePage ? CompleteLessonCheckWhite : CompleteLessonCheckMainSiteWhite) : (isCoursePage ? CompleteLessonCheck : CompleteLessonCheckMainSite)} />}
                            </span>{" "}
                          </Grid>
                        )
                      )}
                    </Grid>
                  </div>
                )}
              </Hidden>
              {/* @ts-ignore */}
              <Hidden smDown>
                <Grid container className={classes.desktopLessonContainer}>
                  {sidebarEntries.map(({ title, isComplete }, index) => (
                    <Grid
                      item
                      className={
                        index == currentLesson
                            ? (isCoursePage ? classes.activeLessonLink : classes.activeLessonLinkMainSite)
                          : (isCoursePage ? classes.lessonLink : classes.lessonLinkMainSite)
                      }
                    >
                      <span
                        className={classes.lessonSpan}
                        onClick={() => {
                          window.scrollTo(0, 0);
                          decideStartingCourse(index);
                          setCurrentLesson(index);
                        }}
                      >
                        {title}  {isComplete && <img className={classes.completedIcon} src={index == currentLesson ? (isCoursePage ? CompleteLessonCheckWhite : CompleteLessonCheckMainSiteWhite) : (isCoursePage ? CompleteLessonCheck : CompleteLessonCheckMainSite)} />}
                      </span>{" "}
                    </Grid>
                  ))}
                </Grid>

                {
                  (facebookUrl || twitterUrl || linkedinUrl || whatsappUrl || mailUrl || pinterestUrl) &&

                  <Grid container>
                    <Grid item xs={12} className={classes.textAlignCenter}>
                      <label className={isCoursePage ? classes.sharingLabel : classes.sharingLabelMainSite}>SHARE THIS COURSE</label>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container alignItems="center" justify="center">
                        {facebookUrl && <Grid item>
                          <a href={facebookUrl} target="_blank" className={classes.sharingAnchor}>
                            <img src={isCoursePage ? FacebookShareIcon : FacebookShareIconMainSite} />
                          </a>
                        </Grid>}
                        {twitterUrl && <Grid item>
                          <a href={twitterUrl} target="_blank" className={classes.sharingAnchor}>
                            <img src={isCoursePage ? TwitterShareIcon : TwitterShareIconMainSite} />
                          </a>
                        </Grid>}
                        {linkedinUrl && <Grid item>
                          <a href={linkedinUrl} target="_blank" className={classes.sharingAnchor}>
                            <img src={isCoursePage ? LinkedinShareIcon : LinkedinShareIconMainSite} />
                          </a>
                        </Grid>}
                        {pinterestUrl && <Grid item>
                          <a href={pinterestUrl} target="_blank" className={classes.sharingAnchor}>
                            <img src={PinterestShareIcon} />
                          </a>
                        </Grid>}
                        {whatsappUrl && <Grid item>
                          <a href={whatsappUrl} target="_blank" className={classes.sharingAnchor}>
                            <img src={isCoursePage ? WhatsappShareIcon : WhatsappShareIconMain} />
                          </a>
                        </Grid>}
                        {mailUrl && <Grid item>
                          <a href={mailUrl} target="_blank" className={classes.sharingAnchor}>
                            <img src={isCoursePage ? EmailShareIcon : EmailShareIconMain} />
                          </a>
                        </Grid>}
                      </Grid>
                    </Grid>
                  </Grid>
                }

              </Hidden>
            </Grid>
            {!matchesMobile &&
              <Grid container className={classes.buttonContainer}>
                {
                  currentLesson !== 0 ?
                    <Grid
                      item
                      xs={12}
                      component="button"
                      className={isCoursePage ? classes.backButton : classes.backButtonMainSite}
                      onClick={() => handleBack()}
                    >
                      BACK
                    </Grid>
                    : <Grid item xs={12}></Grid>
                }

              {
                !hideNextButton && <CampusCTA customBorderRadius={!isCoursePage && "8px"} linkText={(currentLesson === totalLessons) ? 'COMPLETE' : 'NEXT'} buttonType={"rectangular"} arrowIcon={false} pageType={"available courses"}
                  customClickEvent={handleNext} />
              }
                {/*<Grid*/}
                {/*  item*/}
                {/*  xs={12}*/}
                {/*  component="button"*/}
                {/*  className={`${classes.nextButton} ${hideNextButton ? classes.hidden : ''}`}*/}
                {/*  onClick={() => handleNext()}*/}
                {/*>*/}
                {/*  {(currentLesson === totalLessons) ? 'COMPLETE' : 'NEXT'}*/}

                {/*</Grid>*/}

                {!matchesMobile &&
                  // <Grid container className={classes.squiggleContainerBottom} item xs={12}>
                  <>
                    <Grid item xs={12}></Grid>
                    <Grid item xs={12} className={classes.squiggleImgHolder} justify={"center"}
                      container>{squiggleDecider()}</Grid>
                    {/*// </Grid>*/}
                  </>
                }
              </Grid>
            }
            <Hidden smDown>
              <Grid container item className={classes.sponsorContainer}>

                {sponsor && sponsor.courseSponsorName &&
                  <a href={sponsor.courseSponsorLink} target={"_blank"} className={classes.sponsorAnchor}>
                    <img className={classes.sponsorImage} src={imageUrlFor(buildImageObj(sponsor._rawCourseSponsorImgDark))
                      .fit("scale")
                      .auto("format")
                      .url()}
                      alt={sponsor.courseSponsorName}
                      className={classes.logoImage}
                    />
                  </a>
                }
                {/*<button onClick={() => console.log(_rawCourseSponsorImg, courseSponsorName, courseSponsorLink)}>props for img</button>*/}
              </Grid>
            </Hidden>
          </Grid>
        </Container>
      </ContainerForPages>}

    </article>
  );
}

export default CoursePage;
