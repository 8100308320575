import React, { Component, useEffect, useState } from "react";
import {
  Typography,
  Button,
  Grid,
  Collapse,
  Divider,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import IconExpandMore from "./icon/IconExpandMore.svg";
import IconExpandLess from "./icon/IconExpandLess.svg";
import MobileMenuOpenMore from "./icon/mobileMenuOpenMore.svg";
import MobileMenuOpenLess from "./icon/mobileMenuOpenLess.svg";
import MobileMenuOpenLessVisible from "./icon/mobileMenuOpenLessVisible.svg";
import { Link } from "gatsby";
import ContainerForLinks from "./containerForLinks";
import Image from "gatsby-plugin-sanity-image";

const useStyles = makeStyles((theme) => ({
  menuLink: {
    color: "#262641",
    textDecoration: "none",
  },
  campusMenuIcon: {
    paddingRight: "1rem",
  },
  menuLinkCampus: {
    color: "#3C465F",
    textDecoration: "none",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "24px",
    display: "flex",
  },
  circle: {
    padding: "6px",
    borderRadius: "50%",
    height: "2rem",
    width: "2rem",
    backgroundColor: "#ECF1F4",
    "&:hover": {
      backgroundColor: "#ECF1F4",
    },
  },
  circleCampus: {
    padding: "6px",
    border: "0.5px solid #D4D4D4",
    borderRadius: "50%",
    height: "2rem",
    width: "2rem",
    backgroundColor: "#FFFFFF",
    "&:hover":{
      backgroundColor: "#FFFFFF",
    },
  },
  subMenuParent: {
    padding: "21px 0",
    width: "100%",
    backgroundColor: "#ffffff",
    borderBottom: "1px solid #E6EDFB",
  },
  subMenuParentCourse: {
    padding: "21px 0",
    width: "100%",
    backgroundColor: "#ffffff",
  },
  subMenuParentTitle: {
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "27px",
    letterSpacing: "-0.5px",
    color: "#2F2F4E",
  },

  subMenuParentTitleVisible: {
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "27px",
    letterSpacing: "-0.5px",
    color: "#3C465F",
  },
  subMenuParentTitleActive: {
    fontWeight: "600",
  },
  subSubMenuTitle: {
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "24px",
    letterSpacing: "0.04em",
    textAlign: "left",
    color: "#262641",
    textDecoration: "none",
  },
  subSubMenuTitleHasChildren: {
    paddingBottom: "4px",
  },
  subSubMenuText: {
    //styleName: Mobile/Body Text Regular;
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    letterSpacing: 0,
    textAlign: "left",
    color: "#2F2F4E",
    textDecoration: "none",
    padding: "8px 0"
  },
  subSubMenuTextVisible: {
    //styleName: Mobile/Body Text Regular;
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    letterSpacing: 0,
    textAlign: "left",
    color: "#3C465F",
    textDecoration: "none",
    padding: "8px 0"
  },
  headerMenuMobile: {
    width: "100%",
    padding: "0 16px",
  },
  padding: {
    paddingTop: "12px",
  },
  animatedItem: {
    animation: `$myEffect 300ms ${theme.transitions.easing.easeInOut}`,
    border: "0",
    background: "#3C465F",
  },
  animatedItemVisible: {
    animation: `$myEffect 300ms ${theme.transitions.easing.easeInOut}`,
    border: "0",
    background: "#6464FF",
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.1)",
    "&:hover":{
      background: "#6464FF",
    },
  },
  animatedItemExiting: {
    animation: `$myEffectExit 300ms ${theme.transitions.easing.easeInOut}`,

  },
  // list: {
  //   margin: "0",
  // },
  // listWithBorder: {
  //   margin: "0",
  //   paddingBottom: "15px",
  // },
  liColor: {
    color: "#50586C",
  },
  "@keyframes myEffect": {
    "0%": {
      transform: "rotate(-90deg)",
    },
    "100%": {
      transform: "rotate(0deg)",
    },
  },
  "@keyframes myEffectExit": {
    "0%": {
      transform: "rotate(90deg)",
    },
    "100%": {
      transform: "rotate(0deg)",
    },
  },
}));

function HeaderMenuMobile(props) {
  const classes = useStyles();
  const { text, links, reference, isCoursePage, menuIcon } = props;
  const [open, setOpen] = React.useState(null);
  function handleClick() {
    setOpen(!open);
  }

  if (text.toLowerCase() === 'account') return (
    <></>
  );

  return (
    <Grid className={classes.headerMenuMobile}>
      <Grid
        className={classes.subMenuParent}
        container
        item
        alignItems="center"
        justify="space-between"
      >
        <Grid>
          <ContainerForLinks
            reference={reference}
            _type={reference._type}
            customClassName={classes.menuLink}
            isMenuItem={true}
          > {isCoursePage && menuIcon && <Image style={{
            paddingRight: "1rem",
          }} {...menuIcon} />}
            <Typography
              variant="body1"
              className={`${isCoursePage ? classes.subMenuParentTitleVisible : classes.subMenuParentTitle} ${open && classes.subMenuParentTitleActive
                }`}
            >
              {text}{" "}
            </Typography>
          </ContainerForLinks>
        </Grid>
        {links.length !== 0 && (
          <IconButton
            onClick={handleClick}
            size="small"
            className={`${open
                ? isCoursePage ? classes.animatedItemVisible : classes.animatedItem
                : open === false && classes.animatedItemExiting
              } ${isCoursePage ? classes.circleCampus : classes.circle}`}
          >
            {open && (
              <img src={MobileMenuOpenMore} alt="MobileMenuLessOptions" />
            )}{" "}
            {!open && (
              <img src={isCoursePage ? MobileMenuOpenLessVisible : MobileMenuOpenLess} alt="MobileMenuMoreOptions" />
            )}
          </IconButton>
        )}
      </Grid>
      <Grid className={!open ? classes.list : classes.listWithBorder}>
        {links.map((cl) => (
          <Collapse
            in={open}
            timeout="auto"
            unmountOnExit
            key={cl.text}
            className={classes.padding}
          >
            <Typography
              variant="body2"
              className={`${classes.subSubMenuTitle} ${cl.menuChildLinks.length > 0 &&
                classes.subSubMenuTitleHasChildren
                }`}
            >
              <ContainerForLinks
                reference={reference}
                _type={reference._type}
                customClassName={classes.subSubMenuTitle}
              >
                {cl.text}
              </ContainerForLinks>
            </Typography>
            {cl.menuChildLinks &&
              cl.menuChildLinks.map((link) =>
                link.reference ? (
                  <Typography
                    variant="body2"
                    className={isCoursePage ? classes.subSubMenuTextVisible : classes.subSubMenuText}
                  >
                    <ContainerForLinks
                      reference={link.reference}
                      _type={link.reference._type}
                      customClassName={isCoursePage ? classes.subSubMenuTextVisible : classes.subSubMenuText}
                    >
                      {link.text}
                    </ContainerForLinks>
                  </Typography>
                ) : (
                    <Typography
                      variant="body2"
                      className={isCoursePage ? classes.subSubMenuTextVisible : classes.subSubMenuText}
                    >
                      {link.text}
                    </Typography>
                  )
              )}
          </Collapse>
        ))}
      </Grid>
    </Grid>
  );
}

export default HeaderMenuMobile;
