import React, { useState, useEffect, useLayoutEffect} from "react";
import Helmet from "react-helmet";
import Header from "./header";
import Footer from "./footer";
import CssBaseline from "@material-ui/core/CssBaseline";
import {
  makeStyles,
  createMuiTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import { withPrefix, Link } from "gatsby";
import "../styles/layout.css";
import * as styles from "./layout.module.css";
import "@fontsource/poppins/400.css"
import "@fontsource/poppins/500.css"
import "@fontsource/poppins/600.css"
import "@fontsource/poppins/700.css"
import {AuthContext} from "../context/AuthContext";

// Campus fonts
import "@fontsource/space-grotesk/400.css"
import "@fontsource/space-grotesk/500.css"
import "@fontsource/space-grotesk/600.css"
import "@fontsource/space-grotesk/700.css"
import SportingGrotesqueOtfBold from "../../static/fonts/SportingGrotesque-Bold.otf"
import SportingGrotesqueOtfRegular from "../../static/fonts/SportingGrotesque-Regular.otf"

//import favicon from "./icon/favicon/favicon.ico";
import favicon32 from "./icon/favicon/favicon-32x32.png";
import favicon16 from "./icon/favicon/favicon-16x16.png";
import favico from "./icon/favicon/favicon.ico";
import appleTouchIcon from "./icon/favicon/apple-touch-icon.png";
import androidChrome192 from "./icon/favicon/android-chrome-192x192.png";
import androidChrome521 from "./icon/favicon/android-chrome-512x512.png";
import CoursePage from "./coursepage";
//import mstitle from "./icon/favicon/mstitle-150x150.png";
//import android192 from "./icon/favicon/android-chrome-192x192.png";
//import android512 from "./icon/favicon/android-chrome-512x512.png";
//import appletouch from "./icon/favicon/apple-touch-icon.png";
import AuthenticatorNoRedirect from "./auth0/AuthenticatorNoRedirect";
import authenticatorNoRedirect from "./auth0/AuthenticatorNoRedirect";

const useStyles = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
}));


const poppins = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
    local('Poppins'),
    local('Poppins-Regular')
  `,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

const courseFontArray = [poppins]

function Layout(props) {
  const {
    children,
    onHideNav,
    onShowNav,
    showNav,
    headTitle,
    headLogo,
    headlinks,
    footer,
    isCoursePage,
  } = props;

  const [isForumPage, setIsForumPage] = useState(false);

  useEffect(() => {
    if (window.location.href.includes('/forum')) {
      setIsForumPage(true);
    }
  }, []);

  const [showGate, setShowGate] = useState(false);

  useEffect(() => {

    if (typeof window !== "undefined"){
      setShowGate(AuthenticatorNoRedirect())
    }
  }, [])

  const classes = useStyles();
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: "#434A54, 100%",
      },
      secondary: {
        main: "#F5F7FA, 100%",
      },
      typography: {
        fontFamily: (isCoursePage || isForumPage) ? [...courseFontArray] : "Poppins",
      },
      overrides: {
        MuiCssBaseline: {
          "@global": {
            "@font-face": (isCoursePage || isForumPage) ? [...courseFontArray] : [poppins],
          },
        },
      },
    },
  });
  theme.typography.h1 = {
    fontSize: "48px",
    fontFamily: (isCoursePage || isForumPage) ? "Poppins" : "Poppins",
    fontWeight: 700,
    lineHeight: "58px",
    color: "#262641",
    letterSpacing: "-0.02em",
    [theme.breakpoints.down('sm')]: {
      fontSize: "36px",
      lineHeight: "42px",
      letterSpacing: "-0.02em",
    },

    //mobile later
  };
  //styleName: Desktop/Title (H1);


  theme.typography.h2 = {
    fontSize: "36px",
    fontFamily: (isCoursePage || isForumPage) ? "Poppins" : "Poppins",
    fontStyle: "semibold",
    fontWeight: 600,
    lineHeight: "47px",
    color: "#262641",
    letterSpacing: "-0.02em",
    margin: 0,
    marginBottom: "10px",
    //mobile later
    [theme.breakpoints.down(365)]: {
      wordBreak: 'break-word'
    }

  };
  theme.typography.h3 = {
    fontSize: "25px",
    fontFamily: (isCoursePage || isForumPage) ? "Poppins" : "Poppins",
    fontStyle: "semibold",
    fontWeight: 600,
    lineHeight: "36px",
    letterSpacing: "0",
    color: "#262641",
    // paddingTop:"10px", fix this seee why?
    margin: 0,
    //mobile later

  };
  theme.typography.h4 = {
    fontSize: "20px",
    fontFamily: (isCoursePage || isForumPage) ? "Poppins" : "Poppins",
    fontWeight: 600,
    lineHeight: "28px",
    letterSpacing: "-0.02em",
    // paddingTop:"10px", fix this seee why?
    margin: 0,
    //mobile later

  };
  // in figma as small title bold
  theme.typography.h6 = {
    fontSize: "18px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "bold",
    lineHeight: "24px",
    letterSpacing: "0px",
    margin: "0",
    color: "#262641",
    //mobile later

  };
  //in figma as subtitle
  theme.typography.subtitle1 = {
    fontSize: "24px",
    fontFamily: "Poppins",
    fontStyle: "medium",
    fontWeight: 500,
    lineHeight: "30px",
    letterSpacing: "0",
    //mobile later

  };
  //in figma as BODY
  theme.typography.body1 = {
    fontSize: "16px",
    fontFamily: (isCoursePage || isForumPage) ? "Poppins" : "Poppins",
    fontStyle: "regural",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#2F2F4E",

    letterSpacing: "0",
    margin: "0",
    //mobile later
    wordBreak: 'break-word'
  };
  //in figma as small BODY
  theme.typography.body2 = {
    fontSize: "14px",
    fontFamily: (isCoursePage || isForumPage) ? "Poppins" : "Poppins",
    fontStyle: "regural",
    fontWeight: 400,
    lineHeight: "20px",
    letterSpacing: "0",
    color: "#2F2F4E"
    //mobile later

  };
  //in figma as small button ALL CAPS
  theme.typography.button = {
    fontSize: "16px",
    fontFamily: (isCoursePage || isForumPage) ? "Poppins" : "Poppins",
    fontStyle: "semibold",
    fontWeight: 600,
    lineHeight: "24px",
    letterSpacing: "0.04em",
    //mobile later

  };
  // in figma as disclaimer
  theme.typography.subtitle2 = {
    fontSize: "12px",
    fontFamily: (isCoursePage || isForumPage) ? "Poppins" : "Poppins",
    fontStyle: "medium",
    fontWeight: 500,
    lineHeight: "16px",
    letterSpacing: "0",
    //mobile later

  };
  // in figma as link
  // link = {
  //   fontSize: "16px",
  //   fontFamily: "Poppins",
  //   fontStyle: "medium",
  //   fontWeight: 500,
  //   lineHeight: "24px",
  //   letterSpacing: "0",
  //   textAlign: "left",
  //hover underline
  // link colors to be got from sandu
  //mobile later

  // };

  // in figma as quotes
  // quote = {
  //   fontSize: "16px",
  //   fontFamily: "Poppins",
  //   fontStyle: "normal",
  //   fontWeight: 400,
  //   lineHeight: "24px",
  //   letterSpacing: "0",
  //   textAlign: "left",
  //mobile later

  //italic
  // };


  const faviconLinks = [
    { rel: "icon", type: "image/png", sizes: "16x16", href: favicon16 },
    { rel: "icon", type: "image/png", sizes: "32x32", href: favicon32 },
    { rel: "icon", type: "image/png", sizes: "16x16", href: favico },
    { rel: "apple-touch-icon", type: "image/png", sizes: "180x180", href: appleTouchIcon },
    { rel: "icon", type: "image/png", sizes: "512x512", href: androidChrome521 },
    { rel: "icon", type: "image/png", sizes: "192x192", href: androidChrome192 },
  ];


  const [isAuthed, setIsAuthed] = useState()
  useEffect(() => {
       setIsAuthed(authenticatorNoRedirect)
  },[])


  return (
    <>
      <Helmet>

      </Helmet>
      <AuthContext.Provider value={{ isAuthed }}>
        <ThemeProvider theme={theme}>
          <CssBaseline/>
          <Header
            onHideNav={onHideNav}
            onShowNav={onShowNav}
            showNav={showNav}
            headTitle={headTitle}
            headLogo={headLogo}
            headlinks={headlinks}
            isCoursePage={isCoursePage || isForumPage}
            showGate={showGate}
          />

          <div className={styles.content}>
            {children}
          </div>
          {!props.hideFooter && <Footer
            footer={footer}
            onHideNav={onHideNav}
            onShowNav={onShowNav}
            showNav={showNav}
            isCoursePage={isCoursePage || isForumPage}
          />}

          <Helmet link={faviconLinks}>
            <script
              src={withPrefix(process.env.GATSBY_BMTRACKING)}
              type="text/javascript"
            />
            <meta name="robots" content="index,follow"/>

          </Helmet>


          <div class="scrptHolder">

          </div>
        </ThemeProvider>
      </AuthContext.Provider>
    </>
  );
}

export default Layout;
